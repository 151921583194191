import { DatePicker, Form, Input, InputNumber, Modal, Select } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useState } from "react";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";

const AddNotificationModal = ({ setAddModalOpen, addModalOpen, hotel }) => {
  const [loadingHotels, setLoadingHotels] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [notificationTarget, setNotificationTarget] = useState("");
  const [usersOptions, setUsersOptions] = useState([]);
  const [hotelOptions, setHotelOptions] = useState([]);
  const auth = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const onFinish = async (values) => {
    console.log("Success:", values);
    await axios
      .post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/notifications`,
        {
          description: {
            english: values.description,
            russian: values.descriptionRU,
            arabic: values.descriptionAR,
          },
          // NOTE: target [users, user:userID, hotel: hotelID]
          repeatInterval: values.repeatInterval,
          sendDate: values.sendDate,
          title: {
            english: values.title,
            russian: values.titleRU,
            arabic: values.titleAR,
          },
          target: values.target,
          hotel: values.hotel,
          active: true,  // Set 'active' as a boolean instead of 1
          repeat: true,  // Set 'repeat' as boolean if this is the intention
          type: "mobile",
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )

      .then(function (response) {
        console.log(response);
        form.resetFields();
        setNotificationTarget("");
        setAddModalOpen(false);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };
  const handleFormValueChange = (val) => {
    if (val.target) {
      setNotificationTarget(val.target);
      if (val.target === "hotel") {
        if (hotelOptions.length === 0) {
          setLoadingHotels(true);
          axios
            .get(`${import.meta.env.VITE_APP_BASE_API_URL}/hotels/get-hotels`, {
              headers: {
                Authorization: `Bearer ${auth.data.accessToken}`,
              },
            })
            .then((res) => {
              setHotelOptions(
                res.data.hotels.map((hotel) => ({
                  value: hotel.id,
                  label: hotel.name.english,
                }))
              );
              setLoadingHotels(false);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (val.target === "user") {
        if (usersOptions.length === 0) {
          setLoadingUsers(true);
          axios
            .get(
              `${
                import.meta.env.VITE_APP_BASE_API_URL
              }/users/get-users?role[]=${11}`,
              {
                headers: {
                  Authorization: `Bearer ${auth.data.accessToken}`,
                },
              }
            )
            .then((res) => {
              console.log(res);
              setUsersOptions(
                res.data.users.map((user) => ({
                  value: user.id,
                  label: user.email,
                }))
              );
              setLoadingUsers(false);
            });
        }
      }
    }
  };
  const handleCancel = () => {
    form.resetFields();
    setNotificationTarget("");
    setAddModalOpen(false);
  };
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <Modal
      destroyOnClose
      okText={"Create Notification"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={addModalOpen}
    >
      <Form
        onValuesChange={handleFormValueChange}
        form={form}
        layout="vertical"
        name="add-notification"
      >
        <Form.Item
          name="title"
          label="Notification Title"
          rules={[
            {
              required: true,
              message: "Please input a title!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="titleRU"
          label="Notification Title in Russian"
          rules={[
            {
              required: true,
              message: "Please input a title!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="titleAR"
          label="Notification Title in Arabic"
          rules={[
            {
              required: true,
              message: "Please input a title!",
            },
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>
        <Form.Item
          name="target"
          label="Notification Target"
          rules={[
            {
              required: true,
              message: "Please input a target!",
            },
          ]}
        >
          <Select>
            <Select.Option value="users">Users</Select.Option>
            <Select.Option value="user">User</Select.Option>
            <Select.Option value="hotel">Hotel</Select.Option>
          </Select>
        </Form.Item>
        {notificationTarget === "user" ? (
          <Form.Item name="targetUser" label="Target User">
            <Select
              filterOption={filterOption}
              showSearch
              loading={loadingUsers}
              options={usersOptions}
            />
          </Form.Item>
        ) : notificationTarget === "hotel" ? (
          <Form.Item name="hotel" label="Target Hotel">
            <Select loading={loadingHotels} options={hotelOptions} />
          </Form.Item>
        ) : (
          ""
        )}

        <Form.Item
          name="description"
          label="Notification Description"
          rules={[
            {
              required: true,
              message: "Please input a description!",
            },
          ]}
        >
          {/* <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} /> */}
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item
          name="descriptionRU"
          label="Notification Description in Russian"
          rules={[
            {
              required: true,
              message: "Please input a description!",
            },
          ]}
        >
          {/* <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} /> */}
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item
          name="descriptionAR"
          label="Notification Description in Arabic"
          rules={[
            {
              required: true,
              message: "Please input a description!",
            },
          ]}
        >
          {/* <Input.TextArea dir="rtl" autoSize={{ minRows: 2, maxRows: 6 }} /> */}
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item
          name="repeatInterval"
          label="Repeat Interval"
          rules={[
            {
              type: "number",
              required: true,
            },
          ]}
        >
          <InputNumber style={{ width: "100%" }} controls={false} />
        </Form.Item>
        <Form.Item
          label="Send Date"
          name="sendDate"
          rules={[
            {
              required: true,
              message: "Please input a Send date!",
            },
          ]}
          getValueFromEvent={(onChange) =>
            dayjs(onChange).format("YYYY-MM-DD HH:mm")
          }
          getValueProps={(i) => dayjs(i)}
        >
          <DatePicker
            showTime={{ format: "HH:mm" }}
            allowClear={false}
            format="YYYY-MM-DD HH:mm"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddNotificationModal;
