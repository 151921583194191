import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Upload,
} from "antd";
import axios from "axios";
import dayjs from 'dayjs';
import { useState } from "react";
import Swal from "sweetalert2";
import UploadItemIcon from "../../components/UploadIcon";
import UploadMultipleImagesHotel from "../../components/UploadMultipleImagesHotel";
import ReactQuill from "react-quill";



const AddOfferModal = ({ setAddModalOpen, addModalOpen, hotel, refetch }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [hereImage, setHereImage] = useState(false);
  const filterImages = (images) => {
    return images.map(({ fileName, link }) => ({ fileName, link }));
  };



  const onFinish = async (values) => {
    // Ensure the images are filtered correctly
    const filteredImages = filterImages(values.image);
  
    // Create dayjs objects and set the time explicitly to 00:00:00 to avoid timezone shifting
    const validFrom = dayjs(values.validFrom).startOf('day');  // Set to start of day in local timezone
    const validUntil = dayjs(values.validUntil).endOf('day');  // Set to end of day in local timezone
  
    // Format the dates as 'YYYY-MM-DD'
    const formattedValidFrom = validFrom.format('YYYY-MM-DD');
    const formattedValidUntil = validUntil.format('YYYY-MM-DD');
  
    console.log("validFrom: ", formattedValidFrom);  // Formatted for readability
    console.log("validUntil: ", formattedValidUntil);  // Formatted for readability
  
    const payload = {
      title: {
        english: values.title,
        arabic: values.titleAR,
        russian: values.titleRU,
      },
      price: values.price,
      hotel: hotel,
      images: filteredImages,
      validFrom: formattedValidFrom,
      validUntil: formattedValidUntil,
      percentage: values.percentage,
      percentageSign: "%",
      description: {
        english: values.description,
        arabic: values.descriptionAR,
        russian: values.descriptionRU,
      },
    };
  
    // Add optional information if provided
    if (values.information || values.informationAR || values.informationRU) {
      payload.information = {
        english: values.information,
        russian: values.informationRU,
        arabic: values.informationAR,
      };
    }
  
    // Add optional terms and conditions if provided
    if (values.terms || values.termsAR || values.termsRU) {
      payload.termsAndConditions = {
        english: values.terms,
        arabic: values.termsAR,
        russian: values.termsRU,
      };
    }
  
    // Set loading state before making the API call
    setLoading(true);
  
    try {
      // Make the API call to submit the offer
      await axios.post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/offers`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`, // Send token for authorization
          },
        }
      );
  
      // Refetch data and reset the form
      refetch();
      form.resetFields();
      setAddModalOpen(false);
    } catch (error) {
      // Handle errors if the API call fails
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong",
      });
    } finally {
      // Set loading state back to false after the API call
      setLoading(false);
    }
  };
  







  const handleCancel = () => {
    form.resetFields();
    setAddModalOpen(false);
  };
  const selectAfter = (
    <Form.Item noStyle initialValue={"%"} name="percentageSign">
      <p style={{ width: 30 }}>%</p>
    </Form.Item>
  );

  // const getFile = (e) => {
  //   console.log("Upload event:", e);
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };
  return (
    <Modal
      okText={"Add Offer"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      confirmLoading={loading}
      open={addModalOpen}
    >
      <Form form={form} layout="vertical" name="addOfferModal">
        <Form.Item
          name="image"
          label="Photo"
          rules={[
            {
              required: true,
              message: "Please upload at least one image",
            },

          ]}
        >
          <UploadMultipleImagesHotel />
        </Form.Item>

        <Form.Item
          name="title"
          label="Offer Title"
          rules={[
            {
              required: true,
              message: "Please input a title!",
            },
            {
              whitespace: true,
              message: "Please input a valid title!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="titleRU"
          label="Offer Title in Russian"
          rules={[
            {
              required: true,
              message: "Please input a title!",
            },
            {
              whitespace: true,
              message: "Please input a valid title!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="titleAR"
          label="Offer Title in Arabic"
          rules={[
            {
              required: true,
              message: "Please input a title!",
            },
            {
              whitespace: true,
              message: "Please input a valid title!",
            },
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Offer Description"
          rules={[
            {
              required: true,
              message: "Please input a description!",
            },
            {
              whitespace: true,
              message: "Please input a valid description!",
            },
          ]}
        >
          {/* <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} /> */}
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item
          name="descriptionRU"
          label="Offer Description in Russian"
          rules={[
            {
              required: true,
              message: "Please input a description!",
            },
            {
              whitespace: true,
              message: "Please input a valid description!",
            },
          ]}
        >
          {/* <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} /> */}
          <ReactQuill theme="snow" />
        </Form.Item>
        <Form.Item
          name="descriptionAR"
          label="Offer Description in Arabic"
          rules={[
            {
              required: true,
              message: "Please input a description!",
            },
            {
              whitespace: true,
              message: "Please input a valid description!",
            },
          ]}
        >
          {/* <Input.TextArea dir="rtl" autoSize={{ minRows: 2, maxRows: 6 }} /> */}
          <ReactQuill theme="snow" />
        </Form.Item>

        <Form.Item
          name="information"
          label="Offer Information"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please input information!",
        //   },
        //   {
        //     whitespace: true,
        //     message: "Please input a valid information!",
        //   },
        // ]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>
        <Form.Item
          name="informationRU"
          label="Offer Information in Russian"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please input information!",
        //   },
        //   {
        //     whitespace: true,
        //     message: "Please input a valid information!",
        //   },
        // ]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>
        <Form.Item
          name="informationAR"
          label="Offer Information in Arabic"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please input information!",
        //   },
        //   {
        //     whitespace: true,
        //     message: "Please input a valid information!",
        //   },
        // ]}
        >
          <Input.TextArea dir="rtl" autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>
        <Form.Item
          name="terms"
          label="Offer Terms and Conditions"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please input Terms and Conditions!",
        //   },
        //   {
        //     whitespace: true,
        //     message: "Please input a valid information!",
        //   },
        // ]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>
        <Form.Item
          name="termsRU"
          label="Offer Terms and Conditions in Russian"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please input Terms and Conditions!",
        //   },
        //   {
        //     whitespace: true,
        //     message: "Please input a valid information!",
        //   },
        // ]}
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>
        <Form.Item
          name="termsAR"
          label="Offer Terms and Conditions in Arabic"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please input Terms and Conditions!",
        //   },
        //   {
        //     whitespace: true,
        //     message: "Please input a valid information!",
        //   },
        // ]}
        >
          <Input.TextArea dir="rtl" autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>
        <Form.Item
          name="percentage"
          label="Percentage"
          rules={[
            {
              type: "number",
              required: true,
            },
            // {
            //   whitespace: true,
            //   message: "Please input a valid information!",
            // },
          ]}
        >
          <InputNumber addonAfter={selectAfter} />
        </Form.Item>
        <Form.Item
          name="price"
          label="Price"
          rules={[
            {
              type: "number",
              required: true,
              message: "Please input a valid price!",
              min: 0,
            }
          ]}
        >
          <InputNumber style={{ width: "100%" }} controls={false} />
        </Form.Item>

        {/* <Form.Item
          valuePropName={"fileList"}
          getValueFromEvent={getFile}
          name="images"
        >
          <Upload beforeUpload={() => false} multiple>
            <Button icon={<UploadOutlined />}>Select Files</Button>
          </Upload>
        </Form.Item> */}
        {/* <Button onClick={() => console.log(form.getFieldsValue())}></Button> */}
        <Form.Item
          label="Start Date"
          name="validFrom"
          rules={[
            {
              required: true,
              message: "Please input a Start date!",
            },
          ]}
        >
          <DatePicker
            allowClear={false}
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          label="End Date"
          name="validUntil"
          rules={[
            {
              required: true,
              message: "Please input an end date!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const startValue = getFieldValue('validFrom');
                if (!value || !startValue || dayjs(value).isAfter(startValue)) {
                  return Promise.resolve();
                }
                return Promise.reject('End date must be later than start date!');
              },
            }),
          ]}
        >
          <DatePicker
            allowClear={false}
            format="YYYY-MM-DD"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddOfferModal;
