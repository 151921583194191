import { Button, Modal } from "antd";
import { useState } from "react";
import RightChecked from "../../../assets/right-check.svg";
import WrongChecked from "../../../assets/wrong-check.svg";

const ViewLoyalityTier = ({ data, refetch }) => {
  const [modal, setModal] = useState(false);
  const handleCancel = () => {
    setModal(false);
  };
  console.log(data);
  return (
    <div>
      <Button onClick={() => setModal(true)}>View</Button>
      <Modal
        open={modal}
        cancelText={"Close"}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
      >
        <p>
          <b>Tier Name: </b>
          {data.name}
        </p>
        <p>
          <b>Tier Points: </b>
          {data.minPoints} to {data.maxPoints}
        </p>
        <p>
          <b>Tier Discount: </b>
          {data.tierDiscount} %
        </p>
        <p style={{ display: "flex", columnGap: "5px" }}>
          <b>VIP: </b>
          {data.vip ? (
            <img src={RightChecked} width="20px" />
          ) : (
            <img src={WrongChecked} width="20px" />
          )}
        </p>
        <p>
          <b>VIP Treatments: </b>
          <br />
          <span>dummy123</span>
        </p>
        <p>
          <b>Terms and conditions: </b>
          <br />
          <span
            style={{
              padding: "10px",
              border: " 1px solid #33415561",
              display: "inline-block",
              minWidth: "70%",
              borderRadius: "10px",
            }}
          >
            {data.termsAndConditions}
          </span>
        </p>
      </Modal>
    </div>
  );
};

export default ViewLoyalityTier;
