import { Form, Input, Modal } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";

const CreateHotelAreaModal = ({ createModal, setCreateModal, refetch }) => {
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const onFinish = async (values) => {
    console.log("Success:", values);
    await axios
      .post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelAreas`,
        {
          name: values.areaName,
          description: values.areaDesc,
          active: true,
          hotel: selectedHotel.hotelID ? selectedHotel.hotelID : 100378095,
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(function (response) {
        form.resetFields();
        console.log(response);
        refetch();
        setCreateModal(false);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setCreateModal(false);
  };
  return (
    <Modal
      okText={"Add Hotel Area"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={createModal}
      centered
    >
      <Form form={form} layout="vertical" name="add-hotel-area-form">
        <Form.Item
          name="areaName"
          label="Area Name"
          rules={[
            {
              required: true,
              message: "Please input the area name!",
            },
            {
              whitespace: true,
              message: "Please input a valid area name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please input the area description!",
            },
            {
              whitespace: true,
              message: "Please input a valid description!",
            },
          ]}
          label="Description"
          name="areaDesc"
        >
          {/* <Input.TextArea autoSize rows={1} /> */}
          <ReactQuill theme="snow" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateHotelAreaModal;
