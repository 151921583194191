import { combineReducers, configureStore } from "@reduxjs/toolkit";
import hotelReducer from "./slices/hotelSlice";
import notificationsReducer from "./slices/notificationsSlice";
import orderReducer from "./slices/orderSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  hotelReducer,
  notificationsReducer,
  orderReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  blacklist: ["hotelReducer"],
  middleware: [thunk],
});

export const persistor = persistStore(store);
