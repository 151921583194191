function UsersIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.651"
        height="22.905"
        viewBox="0 0 16.651 22.905"
      >
        <defs>
          <clipPath id="clip-path">
            <path
              fill="none"
              d="M0 0H16.651V22.905H0z"
              data-name="Rectangle 18180"
            ></path>
          </clipPath>
        </defs>
        <g data-name="Group 70200">
          <g
            fill="currentColor"
            clipPath="url(#clip-path)"
            data-name="Group 70199"
          >
            <path
              d="M2.8 7.709a5.528 5.528 0 109.116-4.2l.91-2.8A16.294 16.294 0 008.325 0a16.294 16.294 0 00-4.5.713l.912 2.8A5.516 5.516 0 002.8 7.709m9.555 0a4.027 4.027 0 11-4.026-4.028 4.032 4.032 0 014.027 4.028m-7.9-6.66A15.7 15.7 0 018.325.5a15.7 15.7 0 013.867.549L11.5 3.186a5.5 5.5 0 00-6.345 0z"
              data-name="Path 33171"
            ></path>
            <path
              d="M16.357 17.844c-.471-.99-1.6-1.674-3.34-2.031a17.661 17.661 0 00-2.435-.344 25.726 25.726 0 00-4.5 0 17.514 17.514 0 00-2.463.344C3 15.957.925 16.531.294 17.837A3 3 0 000 19.132a3.048 3.048 0 00.3 1.312c.49 1 1.622 1.683 3.341 2.01a15.629 15.629 0 002.369.346 4.548 4.548 0 00.9.064q.73.041 1.463.04c.735 0 1.471-.03 2.2-.091a16.475 16.475 0 002.438-.351c1.747-.345 2.874-1.026 3.35-2.024a3.007 3.007 0 000-2.595M15 19.793c-.254.532-1.065.957-2.305 1.2a15.056 15.056 0 01-2.243.323 25.364 25.364 0 01-3.529.048 3.982 3.982 0 01-.739-.053 14.938 14.938 0 01-2.245-.328 3.147 3.147 0 01-2.293-1.191 1.537 1.537 0 01-.148-.66 1.468 1.468 0 01.146-.642 3.9 3.9 0 012.3-1.212 16.159 16.159 0 012.256-.315c.112-.01.225-.007.337-.015v1.871a3.444 3.444 0 013.581 0v-1.87c.116.008.233.005.349.015a16.32 16.32 0 012.246.317c1.245.255 2.037.673 2.292 1.208a1.512 1.512 0 010 1.3"
              data-name="Path 33172"
            ></path>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default UsersIcon;
