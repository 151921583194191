import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setHotel: (state, action) => {
      state.hotel.hotelName = action.payload.hotelName;
      state.hotel.hotelID = action.payload.hotelID;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHotel } = notificationsSlice.actions;

export default notificationsSlice.reducer;
