import { Button, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import "../../../assets/scss/General.scss";
import { Link } from "react-router-dom";

const LoyalityUsers = () => {
  const [users, setUsers] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  const columns = [
    { title: "ID", dataIndex: "id" },
    { title: "User Email", dataIndex: ["userDetails", "email"] },
    { title: "User Points", dataIndex: "totalPoints" },
    { title: "Expires on", dataIndex: "expiryDate" },
    { title: "Current Tier", dataIndex: ["tierDetails", "name"] },
    {
      title: "Actions",
      render: (_, row) => <Link to={`${row.id}`}>View User</Link>,
    },
  ];

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {},
    sorter: { field: "createdAt", order: "descend" },
  });

  const fetchUsers = () => {
    const { current, pageSize } = tableParams.pagination;
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/loyaltyPoints/get-points?page=${current}&limit=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log("points ", res);
        // Ensure that users is always an array
        setUsers(res.data.usersLoyaltyPoints || []); // Default to an empty array if the response is undefined or null

        // Update pagination with total count of users
        setTableParams((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            total: res.data.totalCount, // Update total count here
          },
        }));
      })
      .catch((error) => {
        console.error(error);
        // Optional: Handle error state
        setUsers([]); // Set an empty array if the request fails
      });
  };

  useEffect(() => {
    fetchUsers();
  }, [tableParams.pagination.current, tableParams.pagination.pageSize]); // Trigger fetch when pagination changes

  const handleTableChange = (pagination) => {
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination: {
        ...pagination,
      },
    }));
  };

  return (
    <div>
      <div className="top-section">
        <div className="exchange">
          <b>Current Exchange Rate </b>
          <p>Earn 1 USD = 1 point </p>
          <p> Redeem 100 point = 5 USD</p>
        </div>
      </div>
      <Table
        rowKey={(record) => record.id}
        dataSource={users || []} // Fallback to empty array
        columns={columns}
        pagination={tableParams.pagination}
        onChange={handleTableChange} // Handle pagination change
      />
    </div>
  );
};

export default LoyalityUsers;
