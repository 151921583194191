import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const DashboardNavigator = () => {
  // NOTE: FIND URL matching role function

  const navigate = useNavigate();
  const findTargetLayoutUrl = (roleID) => {
    // console.log(roleID, "roleid");
    var targetPoint;
    switch (roleID) {
      case 1:
        targetPoint = "/ka/dashboard";
        break;
      case 2:
        targetPoint = "/ma/dashboard";
        break;
      case 3:
        targetPoint = "/a/dashboard";
        break;
      case 4:
        targetPoint = "/fo/dashboard";
        break;
      case 5:
        targetPoint = "/msv/dashboard";
        break;
      case 6:
        targetPoint = "/ms/dashboard";
        break;
      case 7:
        targetPoint = "/hksv/dashboard";
        break;
      case 8:
        targetPoint = "/hks/dashboard";
        break;
      case 9:
        targetPoint = "/rssv/dashboard";
        break;
      case 10:
        targetPoint = "/rss/dashboard";
        break;
      case 12:
        targetPoint = "/it/dashboard";
        break;
      case 14:
        targetPoint = "/its/dashboard";
        break;

      default:
        targetPoint = "/login";
        break;
    }
    return targetPoint;
  };
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    const targetURL = findTargetLayoutUrl(user?.roles?.id);
    navigate(targetURL);
  }, [user]);
};

export default DashboardNavigator;
