import React from "react";

function ExcursionsIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
      >
        <g>
          <path d="M21.125 19.688L13.445 2.32a.82.82 0 00-.265-.36.492.492 0 00-.489-.065c-.16.066-.296.218-.379.421l-2.675 6.73-1.578-2.694c-.13-.188-.313-.297-.504-.297-.2.015-.383.152-.504.37L.14 19.595c-.16.265-.188.633-.063.933.11.285.328.465.567.465H5.93c.008 0 .011.008.015.008h14.657c.222.031.433-.133.527-.41a1.26 1.26 0 00-.004-.895M5.449 12.566l.426.88c.133.214.324.347.531.363l.008-.258.012.25a.497.497 0 00.351-.219l1.332-1.184c.27-.285.329-.808.133-1.191-.195-.383-.582-.488-.87-.242l-.786.723-.328-.653 1.39-2.637 1.329 2.309-3.399 8.535H1.926zm8.278-3.164l-.875-.386a.4.4 0 00-.422-.012l-.668.32-.246-1.261 1.41-3.547 1.554 3.523zm-3.067 1.371a.939.939 0 00.309.493.443.443 0 00.523.086l1.121-.493 1.082.477c.075.059.164.09.25.09h.008l-.004-.04.02.028c.23-.02.43-.215.515-.504l.707-1.262 4.243 9.594H7.07l3.52-8.855zm0 0M3.41 5.988h2.84c.918-.023 1.648-1.047 1.637-2.289.004-.746-.258-1.445-.707-1.879C7.043.934 6.53.238 5.875.05 5.219-.136 4.543.22 4.156.958a1.383 1.383 0 00-.683.441h-.118c-.933.051-1.664 1.118-1.632 2.383.035 1.23.777 2.203 1.687 2.207m-.3-2.3c0-.27.156-.489.355-.497l.05.008c.247.063.5-.058.665-.32.074-.129.187-.203.308-.192h.004c.293.008.555-.23.66-.593.055-.164.16-.281.285-.32.13-.04.262.003.36.117.07.105.11.246.101.386-.015.395.165.754.438.891.156.09.262.305.258.539a.538.538 0 01-.106.344.297.297 0 01-.258.125H3.477c-.204 0-.368-.219-.368-.492M14.867 2.992c.11.008.215-.047.297-.144a.756.756 0 01.54-.164c.023.035.05.074.077.105l.04.05a.497.497 0 00.382.188h.059c.152-.015.297-.117.394-.277a.798.798 0 01.559.156c.094.07.2.102.305.086.21-.015.41-.137.558-.344l.04-.09c.077-.206.093-.449.038-.671a.833.833 0 00-.308-.496c-.493-.368-1.07-.461-1.614-.262-.566-.262-1.187-.188-1.71.207-.313.234-.43.762-.262 1.191.125.293.355.473.605.465m2.64-.285v.027zm0 0"></path>
          <path d="M16.746 3.406c-.316.23-.433.762-.266 1.188.106.285.32.465.555.465h.035a.413.413 0 00.317-.145.774.774 0 01.508-.156c.015.027.023.058.043.082a.558.558 0 00.44.242c.173 0 .34-.098.458-.27.195-.019.39.036.562.16.082.063.176.09.274.087.23.007.45-.13.594-.372l.027-.062c.16-.422.039-.941-.27-1.168-.484-.36-1.054-.453-1.59-.266-.562-.257-1.18-.18-1.699.211m.305 1.375zm2.64 0zm0 0"></path>
        </g>
      </svg>
    </span>
  );
}

export default ExcursionsIcon;
