import { Button, Popconfirm, Table, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddNotificationModal from "./AddNotificationModal";
import dayjs from "dayjs";
import DOMPurify from 'dompurify';

// render description html
const renderHTML = (htmlString) => {
  const cleanHTML = DOMPurify.sanitize(htmlString);
  return (
    <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
  );
};

const PushNotificationsTab = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const columns = [
    {
      title: "Title",
      dataIndex: ["title", "english"],
    },
    {
      title: "Description",
      dataIndex: ["description", "english"],
      render: (htmlContent) => renderHTML(htmlContent),
    },
    {
      title: "Targets",
      dataIndex: "target",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Repeat Interval",
      dataIndex: "repeatInterval",
    },
    {
      title: "Send Date",
      dataIndex: "sendDate",
      render: (date) => dayjs(date).format("YYYY-MM-DD hh:mm A"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (record, rowIndex) => (
        <div className="row-actions">
          {rowIndex.active ? (
            <Popconfirm
              title="Deactivate Notification"
              description="Are you sure to deactivate this notification?"
              onConfirm={() => handleActiveClick(record, rowIndex)}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={buttonLoading} danger type="primary">
                Deactivate
              </Button>
            </Popconfirm>
          ) : (
            <Popconfirm
              title="Activate Notification"
              description="Are you sure you want to activate this notification?"
              onConfirm={() => handleActiveClick(record, rowIndex)}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={buttonLoading} type="primary">
                Activate
              </Button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  const fetchData = (currentPage = 1, pageSize = 10) => {
    setLoading(true);
    console.log("Fetching data for page:", currentPage, "Page size:", pageSize);
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/notifications/get-notifications?limit=${pageSize}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log("Fetched data:", res.data);
        setData(res.data.notifications);
        setLoading(false);
        setTableParams((prevParams) => ({
          ...prevParams,
          pagination: {
            ...prevParams.pagination,
            total: res.data.totalCount,  // This is crucial for pagination
          },
        }));
      })
      .catch((error) => {
        console.error("Error fetching data", error);
        setLoading(false);
      });
  };


  const handleAddNotification = () => {
    setAddModalOpen(true);
  };

  const handleActiveClick = (record, rowIndex) => {
    setButtonLoading(true);
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/notifications?id=${rowIndex.id}`,
        {
          active: !rowIndex.active,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        }
      )
      .then(() => {
        fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
      })
      .finally(() => {
        setButtonLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // Log to track pagination changes
    console.log("Pagination changed:", pagination);

    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // Fetch data based on updated pagination
    fetchData(pagination.current, pagination.pageSize);
  };

  useEffect(() => {
    fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
  }, [addModalOpen, tableParams.pagination.current, tableParams.pagination.pageSize]);

  return (
    <div>
      <div className="table-header">
        <Typography.Title level={2}>
          {/* {selectedHotel.hotelName} Offers List */}
        </Typography.Title>
        <Button
          type="primary"
          style={{ marginBottom: 24 }}
          onClick={handleAddNotification}
        >
          Create Notification
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{
          ...tableParams.pagination,
          total: tableParams.pagination.total, // Ensure this is set to 97
        }}
        loading={loading}
        onChange={handleTableChange}
      />


      <AddNotificationModal
        hotel={selectedHotel}
        setAddModalOpen={setAddModalOpen}
        addModalOpen={addModalOpen}
      />
    </div>
  );
};

export default PushNotificationsTab;
