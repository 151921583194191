import { Button, Form, Input, InputNumber, Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import UploadItemIcon from "../../components/UploadIcon";

const EditFrontOfficeItem = ({ item, refetch }) => {
  console.log(item);
  const [editForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [editItemModal, setEditItemModal] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const onFinish = async (values) => {
    setLoading(true);
    console.log("Success:", values);
    await axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
        {
          icon: values.icon,
          name: {
            english: values.itemName,
            arabic: values.itemNameAR,
            russian: values.itemNameRU,
          },
          description: {
            english: "desc",
            russian: "desc",
            arabic: "desc",
          },
          price: 0,
          timer: values.timer,
          serviceStandaredID:values.serviceStandaredID,

        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        refetch();
        setEditItemModal(false);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    editForm.resetFields();
    setEditItemModal(false);
  };
  return (
    <>
      <Button
        style={{ marginRight: 16 }}
        onClick={() => setEditItemModal(true)}
      >
        View
      </Button>
      <Modal
        confirmLoading={loading}
        okText={"Edit Item"}
        onOk={() => {
          editForm
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={handleCancel}
        open={editItemModal}
        // destroyOnClose
        centered
      >
        <Form
          form={editForm}
          initialValues={{
            itemName: item?.name?.english,
            itemNameRU: item?.name?.russian,
            itemNameAR: item?.name?.arabic,
            timer: item?.timer,
            serviceStandaredID:item?.serviceStandaredID,
            icon:item?.icon,

          }}
          layout="vertical"
          name="edit-service-item-modal"
        >
          <Form.Item name="icon">
            <UploadItemIcon />
          </Form.Item>

          <Form.Item
            name="itemName"
            label="Item Name"
            rules={[
              {
                required: true,
                message: "Please input the item name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="itemNameRU"
            label="Item Name in Russian"
            rules={[
              {
                required: true,
                message: "Please input the item name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="itemNameAR"
            label="Item Name in Arabic"
            rules={[
              {
                required: true,
                message: "Please input the item name!",
              },
            ]}
          >
            <Input dir="rtl" />
          </Form.Item>
          <Form.Item
            name="timer"
            label="Time Estimated (minutes)"
            rules={[
              {
                type: "number",
                required: true,
                message: "Please input the Time Estimate!",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} controls={false} />
          </Form.Item>
          <Form.Item
            name="serviceStandaredID"
            label="serviceStandared ID "
          >
            <Input type="number" min={1} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditFrontOfficeItem;
