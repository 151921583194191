import { Button, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import ViewUserModal from "./ViewUserModal";
import axios from "axios";
import AddUserModal from "./AddUserModal";
import { useSelector } from "react-redux";
import PlusIcon from "../../components/icons/PlusIcon";

const ITStaff = () => {
  const tabRole = 14;
  const auth = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [data, setData] = useState([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null); // Store the current user's data
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "userProfile",
      render: (userProfile) => `${userProfile?.firstName ?? ""} ${userProfile?.lastName ?? ""}`,
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "active",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      onFilter: (a, entry) => entry.active === a,
      render: (status) => (status ? "Active" : "Inactive"),
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Actions",
      render: (text, record) => (
        <Button onClick={() => handleViewClick(record)}>View</Button>
      ),
    },
  ];

  const fetchData = () => {
    setLoading(true);
    axios
      .get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/users/get-users?role[]=${tabRole}&hotel=${
          selectedHotel?.hotelID ? selectedHotel.hotelID : 100378095
        }`,
        {
          headers: {
            Authorization: `Bearer ${auth?.data?.accessToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.users); // Store the users in the state
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      });
  };

  const handleAddUser = () => {
    setAddModalOpen(true);
  };

  const handleViewClick = (record) => {
    setModalData(record); // Pass the correct user data (record) to the modal
    setViewModalOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, [viewModalOpen, addModalOpen, selectedHotel]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <div>
      <div className="table-header">
        <Typography.Title level={2}>IT Staff List</Typography.Title>
        <Button type="primary" onClick={handleAddUser} icon={<PlusIcon />}>
          Add User
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data} // Set the user data for the table
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      {viewModalOpen && modalData && (
        <ViewUserModal
          setModalOpen={setViewModalOpen}
          data={modalData} // Pass the correct modal data, including email
          modalOpen={viewModalOpen}
        />
      )}
      {addModalOpen && (
        <AddUserModal
          tabRole={tabRole}
          setAddModalOpen={setAddModalOpen}
          addModalOpen={addModalOpen}
        />
      )}
    </div>
  );
};

export default ITStaff;
