import { Button, Flex, Table, Typography } from "antd";
import axios from "axios";
import { useRef, useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import { BiSolidComment } from "react-icons/bi";
import CreateOrderMenu from "./CreateOrderMenu";
import { useSelector } from "react-redux";
import ViewOrderModal from "./ViewOrderModal";
import "../../assets/scss/General.scss";
import TableSearch from "../../components/TableSearch";
import DownloadDataButton from "../../components/DownloadDataButton";
import AssignStaffSelect from "./AssignStaffSelect";
import DateFilterDropdown from "./DateFilterDropdown";
import OrderStatusSelect from "./OrderStatusSelect";

const Orders = () => {
  const adminRoles = [1, 12, 3, 4, 14, 5, 9, 7]; // Removed supervisor roles
  const supervisorRoles = [5, 7, 9]; // Supervisor roles
  const staffRoles = [6, 8, 10]; // Staff roles

  const assigneeServices = ["Housekeeping", "Maintenance", "Room Service"];
  const [data, setData] = useState();
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [orderDetailsModal, setOrderDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState([dayjs(), dayjs()]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: false,
    },
  });
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const searchInput = useRef(null);
  const handleSearch = (_, confirm) => {
    confirm();
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };
  const roleServiceMap = {
    5: "Maintenance", // Maintenance Supervisor
    6: "Maintenance", // Maintenance Staff
    7: "Housekeeping", // Housekeeping Supervisor
    8: "Housekeeping", // Housekeeping Staff
    9: "Room Service", // Room Service Supervisor
    10: "Room Service", // Room Service Staff
    // Add other roles and services as needed
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <TableSearch
        searchInput={searchInput}
        dataIndex={dataIndex}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        handleSearch={handleSearch}
        handleReset={handleReset}
        clearFilters={clearFilters}
        confirm={confirm}
        close={close}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#0d375c" : undefined,
        }}
      />
    ),

    onFilter: (value, record) => {
      const roomNoValue = record.roomDetails?.roomNo; // Safe access using optional chaining
      if (roomNoValue) {
        return roomNoValue.toString() === value;
      }
      return false; // Return false for rows with no room number
    },
  });

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      fixed: "left",
      sorter: (a, b) => a.id - b.id, // Sorting by Order ID
      defaultSortOrder: "descend", // Default sorting in descending order
    },
    {
      title: "Item Name",
      dataIndex: "orderItems",
      key: "name",
      render: (details) =>
        details.map((item) => (
          <p key={item.id}>
            {item?.quantity}x {item?.itemDetails?.name?.english}{" "}
            {item?.comment !== null && <BiSolidComment color="#0d375c" />}
          </p>
        )),
      ...getColumnSearchProps("English"), // Adds search for English item names
      onFilter: (value, record) =>
        record.orderItems?.some((item) =>
          item.itemDetails?.name?.english?.toLowerCase().includes(value.toLowerCase())
        ),
    },
    {
      title: "Item Name (AR)",
      dataIndex: "orderItems",
      key: "nameAR",
      render: (details) =>
        details.map((item) => (
          <p key={item.id}>
            {item?.quantity}x {item?.itemDetails?.name?.arabic}{" "}
            {item?.comment !== null && <BiSolidComment color="#0d375c" />}
          </p>
        )),
      ...getColumnSearchProps("Arabic"), // Adds search for Arabic item names
      onFilter: (value, record) =>
        record.orderItems?.some((item) =>
          item.itemDetails?.name?.arabic?.toLowerCase().includes(value.toLowerCase())
        ),
    },

    {
      title: "Service",
      dataIndex: "orderType",
      render: (details) => `${details?.english}`,
      filters: [
        {
          text: "Housekeeping",
          value: "housekeeping",
        },
        {
          text: "Room Service",
          value: "room Service",
        },
        {
          text: "Maintenance",
          value: "maintenance",
        },
        {
          text: "Outlets",
          value: "outlets",
        },
        {
          text: "Excursions",
          value: "excursions",
        },
      ],
      onFilter: (value, record) =>
        record.orderType?.english.toLowerCase() === value.toLowerCase(),
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "Pending", value: "pending" },
        { text: "Done", value: "done" },
        { text: "Cancelled", value: "cancelled" },
        { text: "In Progress", value: "inProgress" },
      ],
      onFilter: (value, record) => record.status.indexOf(value) > -1,
      defaultFilteredValue: ["pending", "inProgress"],
      render: (status, row) => (
        <OrderStatusSelect
          status={status}
          row={row}
          user={user}
          refetch={fetchData} // Pass the refetch function here
        />
      ),
    },

    {
      title: "Assigned To",
      dataIndex: ["assignedUserDetails", "id"],
      render: (_, row) => {
        if (row.orderType?.english !== "Front Office") {
          return <AssignStaffSelect user={user} row={row} />;
        } else {
          // Display 'Front Office' text
          return <span>Front Office</span>;
        }
      },
    },

    {
      title: "Room No.",
      dataIndex: ["roomDetails", "roomNo"],
      ...getColumnSearchProps("roomDetails.roomNo"),
    },
    {
      title: "Area",
      dataIndex: ["areaDetails", "name"],
    },
    {
      title: "Create Date",
      dataIndex: "createdAt",
      render: (date) => <div>{dayjs(date).format("DD/MM/YYYY - H:mm")}</div>,
      sorter: (a, b) => dayjs(a.createdAt).isAfter(dayjs(b.createdAt)),
      defaultSortOrder: "descend",
      filterDropdown: () => (
        <DateFilterDropdown
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
        />
      ),
      onFilter: (value, record) => {
        return dayjs(record.createdAt).isSame(value, 'day');
      }

    },
    {
      title: "Comment",
      dataIndex: "comment",
      width: "10%",
    },
    {
      title: "Created By",
      dataIndex: ["creator", "email"],
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "actions",
      render: (_, order) => (
        <Button onClick={() => handleViewClick(order.id)}>Order Details</Button>
      ),
    },
  ];

  const handleViewClick = (orderID) => {
    setSelectedOrder(orderID);
    setOrderDetailsModal(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    // Updating table params with pagination data
    setTableParams({
      pagination: {
        ...pagination,
        // You can include default pageSize if necessary, e.g., 10
        pageSize: pagination.pageSize || 10,
      },
      filters,
      sorter,
    });

    // Reset the data when the page size changes
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]); // Optional, if you want to clear the data when page size changes
    }

    // Use pagination.pageSize and pagination.current to fetch data correctly
    // You may want to call your data fetching function here based on new pagination values
    fetchData(pagination.current, pagination.pageSize);
  };

  const fetchData = () => {
    setLoading(true);
    const startDate = dateFilter?.[0]
      ? dayjs(dateFilter[0]).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD");
    const endDate = dateFilter?.[1]
      ? dayjs(dateFilter[1]).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD");

    const getUrlByRole = () => {
      if (adminRoles.includes(user.roles.id)) {
        return `${import.meta.env.VITE_APP_BASE_API_URL}/orders/get-orders?hotel=${selectedHotel.hotelID || 100378095}&startDate=${startDate}&endDate=${endDate}&page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}`;
      } else {
        // Similar logic for other roles
        return `${import.meta.env.VITE_APP_BASE_API_URL}/orders/get-orders?assignedUser=${user.data.userProfile.userDetails.id}&startDate=${startDate}&endDate=${endDate}&page=${tableParams.pagination.current}&limit=${tableParams.pagination.pageSize}`;
      }
    };

    axios
      .get(getUrlByRole(), {
        headers: {
          Authorization: `Bearer ${user?.data?.accessToken}`,
        },
      })
      .then((res) => {
        const { orders, totalCount } = res.data; // Destructure totalCount from response
        setData(orders);
        setLoading(false);

        // Calculate total pages based on totalCount and pageSize
        const totalPages = Math.ceil(totalCount / tableParams.pagination.pageSize);

        setTableParams((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            total: totalCount,  // Total number of records
            pageSize: tableParams.pagination.pageSize,
            totalPages: totalPages,  // Optional, you can display it if needed
          },
        }));
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };



  useEffect(() => {
    fetchData();
  }, [selectedHotel, dateFilter]);
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 50000);
    return () => clearInterval(interval);
  }, []);

  // NOTE:  Stringifying the object to compare its previous state correctly, could be changed with lodash isEqual for better performance


  const handleRowTimer = (record) => {
    const biggestTimer = Math.max(
      record.orderItems.map((item) => item.itemDetails.timer)
    );
    if (
      biggestTimer <
      Math.abs(
        dayjs(record.createdAt).diff(dayjs(), "minute") &&
        record.status !== "done"
      )
    ) {
      return "late";
    }
  };

  return (
    <div>
      <div className="table-header">
        <Typography.Title level={2}>Orders List</Typography.Title>
        <Flex gap={16} className="buttons-wrapper">
          {adminRoles.includes(user.roles.id) ? (
            <Button type="primary" onClick={() => setAddModalOpen(true)}>
              Create Order
            </Button>
          ) : (
            ""
          )}

          <DownloadDataButton style={{ marginLeft: "auto" }} data={data} />
        </Flex>
      </div>
      <Table
        rowClassName={(record) => handleRowTimer(record)}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{
          current: tableParams.pagination.current,
          pageSize: tableParams.pagination.pageSize,
          total: tableParams.pagination.total, // Set the total number of records
        }}
        loading={loading}
        onChange={handleTableChange}
        scroll={{
          x: 1700,
        }}
        className="orders-table"
      />

      {orderDetailsModal && (
        <ViewOrderModal
          orderDetailsModal={orderDetailsModal}
          setOrderDetailsModal={setOrderDetailsModal}
          orderID={selectedOrder}
          refetch={fetchData}
        />
      )}
      {addModalOpen && (
        <CreateOrderMenu
          setAddModalOpen={setAddModalOpen}
          addModalOpen={addModalOpen}
          refetch={fetchData}
        />
      )}
    </div>
  );
};

export default Orders;
