import { Rate, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const ActionsLog = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Operation",
      dataIndex: "operation",
    },
    {
      title: "Table",
      dataIndex: "tableName",
    },
    {
      title: "Old Data",
      dataIndex: "oldRecordData",
      render: (data) => JSON.stringify(data),
    },
    {
      title: "New Data",
      dataIndex: "newRecordData",
      render: (data) => JSON.stringify(data),
    },
  ];

  const fetchData = () => {
    setLoading(true);
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/logHistory/get-log-history`,
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setData(res.data.logHistory);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
          },
        });
      });
  };

  useEffect(() => {
    fetchData();
  }, [selectedHotel]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };
  return (
    <div>
      <div className="table-header">
        <Typography.Title level={2}>Log History</Typography.Title>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default ActionsLog;
