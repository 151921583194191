import { Button, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ViewItem from "../../components/ViewItem";
import ChangeItemStatusButton from "../../components/ChangeItemStatusButton";
import AddRoomServiceItem from "./AddRoomServiceItem";
import EditRoomServiceItem from "./EditRoomServiceItem";

const RoomServiceCategory = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const [addItemModal, setAddItemModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/items/get-items?serviceCategory=${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.items);
        setLoading(false);
      });
  };

  const handleDeleteClick = (record, rowIndex) => {
    console.log(rowIndex);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#0d375c",
      confirmButtonText: "Yes, delete it!",
      preConfirm: () => {
        return axios
          .delete(
            `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${record.id}`,
            {
              headers: {
                Authorization: `Bearer ${auth.data.accessToken}`,
              },
            }
          )
          .then(() => {
            // setIsDeleting((prev) => !prev);
            fetchData();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["name", "english"],
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Actions",
      key: "actions",
      width: "30%",
      render: (record) => (
        <>
          <EditRoomServiceItem item={record} refetch={fetchData} />
          <Button
            danger
            dashed="true"
            onClick={() => handleDeleteClick(record)}
          >
            Delete
          </Button>
          <ChangeItemStatusButton item={record} refetch={fetchData} />
        </>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
        }}
        className="top-controls"
      >
        <Button
          onClick={() => setAddItemModal(true)}
          type="primary"
          style={{ marginRight: 16, textAlign: "right" }}
        >
          Add Item
        </Button>
      </div>
      <Table
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={data}
      />

      <AddRoomServiceItem
        addItemModal={addItemModal}
        setAddItemModal={setAddItemModal}
        clickedCategory={params}
        refetch={fetchData}
      />
    </div>
  );
};

export default RoomServiceCategory;
