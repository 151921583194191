// Import the functions you need from the SDKs you need
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB2wDDP4Yq8Ae_6W_dyAcyzBmTvcSDM-mk",
  authDomain: "stella-137f1.firebaseapp.com",
  projectId: "stella-137f1",
  storageBucket: "stella-137f1.appspot.com",
  messagingSenderId: "579373406982",
  appId: "1:579373406982:web:b56f833ca31ec625449c87",
  measurementId: "G-GC0TRQP30K",
};

// get user token
const user = JSON.parse(localStorage.getItem("user"));
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export const requestPermission = () => {
  if (user?.data?.accessToken) {
    // console.log("Requesting User Permission......");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // console.log("Notification User Permission Granted.");
        return getToken(messaging, {
          vapidKey: `BFRZJi8nSdF8nsMZatIfBHnqkjmMDVliH0ywTJEQEim5_nKtQnNe7EKbgr7a6tKe2M9sA8vv8DirhRWuGJ3i634`,
        })
          .then((currentToken) => {
            if (currentToken) {
              axios.post(
                `${import.meta.env.VITE_APP_BASE_API_URL}/users/fcm`,
                {
                  fcmToken: currentToken,
                  type: "web", //or "mobile",
                  user: user?.data.userProfile.id,
                },
                {
                  headers: {
                    Authorization: `Bearer ${user?.data?.accessToken}`,
                  },
                }
              );
              // console.log("Client Token: ", currentToken);
            } else {
              console.log("Failed to generate the app registration token.");
            }
          })
          .catch((err) => {
            console.log(
              "An error occurred when requesting to receive the token.",
              err
            );
          });
      } else {
        // console.log("User Permission Denied.");
      }
    });
  }
};

// requestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log(payload, "fb");
      resolve(payload);
    });
  });
