import { DatePicker, Form, Input, Modal, Select } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
const { Option } = Select;
import { nationalities } from "../../assets/data/nationalities";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const dateFormat = "YYYY-MM-DD";

const AddUserModal = ({ setAddModalOpen, addModalOpen, tabRole }) => {
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));

  // Function to calculate minimum birthdate allowed (10 years ago)
  const getMinBirthdate = () => {
    return dayjs().subtract(10, "years");
  };

  const onFinish = async (values) => {
    console.log("Success:", values);
    await axios
      .post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/users/`,
        {
          email: values?.email,
          password: values?.password,
          role: tabRole,
          firstName: values?.firstname,
          lastName: values?.lastname,
          gender: values?.gender,
          nationality: values?.nationality,
          mobileNumber: values?.mobile,
          dob: values?.dob,
          // CHECK if selectedHotel exists then send it as hotel ID if not, it checks user's assigned hotel, if not it defaults to Dubai Marina Hotel
          hotel: selectedHotel.hotelID
            ? selectedHotel.hotelID
            : user.data.userProfile.userDetails.hotelDetails.id
            ? user.data.userProfile.userDetails.hotelDetails.id
            : 100378095,
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        setAddModalOpen(false);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const handleCancel = () => {
    setAddModalOpen(false);
  };

  // Disable dates that are within the last 10 years from today
  const disabledDate = (current) => {
    return current && current >= getMinBirthdate();
  };

  return (
    <Modal
      okText={"Add user"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={addModalOpen}
      centered
    >
      <Form form={form} layout="vertical" name="addUserModal">
        <Form.Item
          name="firstname"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Please input the First Name!",
            },
            {
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastname"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Please input the Last Name!",
            },
            {
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="nationality"
          label="Nationality"
          rules={[
            {
              required: true,
              message: "Please input the Nationality!",
            },
            {
              whitespace: true,
            },
          ]}
        >
          <Select showSearch>
            {nationalities.map((role) => (
              <Option key={role.name.common} value={role.name.common}>
                {role?.name?.common}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="mobile"
          label="Phone Number"
          type="number"
          rules={[
            {
              required: true,
              message: "Please input the Phone Number!",
              len: 11,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: "email",
              required: true,
            },
            {
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input user password!",
              // len:  < 6,
              // message: "Minimum is 6 characters",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Birthdate"
          name="dob"
          rules={[
            {
              required: true,
              message: "Please input Date of birth!",
            },
          ]}
          getValueFromEvent={(onChange) => dayjs(onChange).format("YYYY-MM-DD")}
          getValueProps={(i) => dayjs(i)}
        >
          <DatePicker
            allowClear={false}
            defaultValue={dayjs("1998-12-23", dateFormat)}
            style={{ width: "100%" }}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
          <Select>
            <Option value="male">Male</Option>
            <Option value="female">Female</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
