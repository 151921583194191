import { Button, Form, Input, Modal } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UploadItemIcon from "../../components/UploadIcon";
import ReactQuill from "react-quill";

const ActivitiesFacilitiesEditItem = ({ item, refetch }) => {
  const [editForm] = Form.useForm();
  const [editItemModal, setEditItemModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [data, setData] = useState([]);
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    if (item) {
      fixed_phone_opening(item);
      setDataReady(true); // Set the data as ready after processing
    }
  }, [item,editItemModal]);



  const onFinish = async (values) => {
    console.log("Success:", values);
    setLoading(true);

    // Prepare the payload
    const payload = {
      name: {
        english: values.itemName,
        arabic: values.itemNameAR,
        russian: values.itemNameRU,
      },
      description: {
        english: values.description,
        arabic: values.descriptionAR,
        russian: values.descriptionRU,
      },
      extraFields: {
        openingHours: values.openingHours,
        phone: values.phone,
      },
      price: 0,
      timer: 0,
      serviceStandaredID: values.serviceStandaredID?values.serviceStandaredID:null,
      icon: values.icon ,
    };

       

    try {
      const response = await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );

      console.log(response);
      refetch(); // Refetch the data after a successful update
      setEditItemModal(false); // Close the edit modal
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const fixed_phone_opening = (item) => {
    let extraFields = item.extraFields;
  
    if (typeof extraFields === "string") {
      try {
        extraFields = JSON.parse(extraFields);
      } catch (error) {
        console.error("Failed to parse extraFields:", error);
        extraFields = {}; 
      }
    }
  
    const transformedData = {
      ...item,
      openingHours: Number(extraFields?.openingHours), 
      phone: Number(extraFields?.phone), 
    };  
    setData(transformedData);
  
    editForm.setFieldsValue({
      phone: transformedData.phone,
      openingHours: transformedData.openingHours,
      itemName: transformedData.name?.english,
      itemNameRU: transformedData.name?.russian,
      itemNameAR: transformedData.name?.arabic,
      description: transformedData.description?.english,
      descriptionRU: transformedData.description?.russian,
      descriptionAR: transformedData.description?.arabic,
      
    });
  };
  

  const handleCancel = () => {
    editForm.resetFields();
    setEditItemModal(false);
  };
  return (
    <>
      <Button
        style={{ marginRight: 16 }}
        onClick={() => setEditItemModal(true)}
        disabled={!dataReady} 
      >
        View
      </Button>
      <Modal
        confirmLoading={loading}
        okText={"Edit Item"}
        onOk={() => {
          editForm
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={handleCancel}
        open={editItemModal}
        centered
      >
        <Form
          form={editForm}
          initialValues={{
            itemName: data?.name?.english,
            itemNameRU: data?.name?.russian,
            itemNameAR: data?.name?.arabic,
            icon: data?.icon,
            description: data?.description?.english,
            descriptionRU: data?.description?.russian,
            descriptionAR: data?.description?.arabic,
            phone: data?.extraFields?.phone,
            openingHours: data?.extraFields?.openingHours,
            serviceStandaredID: data?.serviceStandaredID,

          }}
          layout="vertical"
          name="edit-service-item-modal"
        >
          <Form.Item name="icon">
            <UploadItemIcon />
          </Form.Item>
          <Form.Item
            name="itemName"
            label="Item Name"
            rules={[
              {
                required: true,
                message: "Please input the item name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="itemNameRU"
            label="Item Name in Russian"
            rules={[
              {
                required: true,
                message: "Please input the item name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="itemNameAR"
            label="Item Name in Arabic"
            rules={[
              {
                required: true,
                message: "Please input the item name!",
              },
            ]}
          >
            <Input dir="rtl" />
          </Form.Item>
          <Form.Item label="Description" name="description">
            {/* <Input.TextArea /> */}
            <ReactQuill theme="snow" />
          </Form.Item>
          <Form.Item label="Description in Russian" name="descriptionRU">
            {/* <Input.TextArea /> */}
            <ReactQuill theme="snow" />
          </Form.Item>
          <Form.Item label="Description in Arabic" name="descriptionAR">
            {/* <Input.TextArea dir="rtl" /> */}
            <ReactQuill theme="snow" />
          </Form.Item>
          <Form.Item label="Phone Number" name="phone">
            <Input />
          </Form.Item>
          <Form.Item label="Opening Hours" name="openingHours">
            <Input />
          </Form.Item>
          <Form.Item
            name="serviceStandaredID"
            label="serviceStandared ID "
          >
            <Input type="number" min={1} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ActivitiesFacilitiesEditItem;
