function OrdersIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.12"
        height="19.716"
        viewBox="0 0 20.12 19.716"
      >
        <g data-name="Iconly/Light/Buy" transform="translate(.867 .867)">
          <g
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            transform="translate(-.75 -.75)"
          >
            <path
              d="M5.422 17.32a.765.765 0 11-.764.765.766.766 0 01.764-.765z"
              data-name="Stroke 1"
            ></path>
            <path
              d="M16.675 17.32a.765.765 0 11-.765.765.766.766 0 01.765-.765z"
              data-name="Stroke 3"
            ></path>
            <path
              d="M.75.75l2.08.36.963 11.473a1.8 1.8 0 001.8 1.653h10.909a1.8 1.8 0 001.785-1.546l.949-6.558a1.341 1.341 0 00-1.327-1.533H3.164"
              data-name="Stroke 5"
            ></path>
            <path d="M12.125 8.295h2.773" data-name="Stroke 7"></path>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default OrdersIcon;
