import { Button, Typography } from "antd";
import EditServiceItem from "./EditServiceItemModal";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import EditOutletServiceItem from "./EditOutletServiceItem";

const ServiceItem = ({ item, refetch }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [editItemModal, setEditItemModal] = useState(false);
  const [clickedItem, setClickedItem] = useState({});
  const handleEditItem = (item) => {
    setClickedItem(item);
    setEditItemModal(true);
  };

  const handleDeleteClick = (record, rowIndex) => {
    console.log(rowIndex);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#0d375c",
      confirmButtonText: "Yes, delete it!",
      preConfirm: () => {
        return axios
          .delete(
            `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
            {
              headers: {
                Authorization: `Bearer ${user.data.accessToken}`,
              },
            }
          )
          .then(() => {
            // setIsDeleting((prev) => !prev);
            refetch();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
      },
    });
  };
  return (
    <div
      className="collapse-item"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0 0 0",
        borderBottom: "1px solid #d9d9d9",
        paddingBottom: 20,
      }}
      key={item?.name?.english}
    >
      <div className="left">
        <Typography.Text style={{ marginRight: 10 }}>
          {item?.name?.english}
        </Typography.Text>
        <Typography.Text>${item.price}</Typography.Text>
      </div>

      <div className="right">
        <Button
          style={{ marginRight: 10 }}
          onClick={() => handleEditItem(item)}
        >
          Edit
        </Button>

        <Button type="dashed" danger onClick={handleDeleteClick}>
          Delete
        </Button>
      </div>

      <EditOutletServiceItem
        setEditItemModal={setEditItemModal}
        editItemModal={editItemModal}
        refetch={refetch}
        item={clickedItem}
      />
    </div>
  );
};

export default ServiceItem;
