import { Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { filter as _filter } from "lodash";
import { useSelector } from "react-redux";

const AssignStaffSelect = ({ user, row }) => {
  const staffRoles = [6, 8, 10];
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [selectLoading, setSelectLoading] = useState(false);
  const [housekeepingStaff, setHousekeepingStaff] = useState([]);
  const [maintenanceStaff, setMaintenanceStaff] = useState([]);
  const [roomServiceStaff, setRoomServiceStaff] = useState([]);

  const fetchAssignees = () => {
    axios
      .get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/users/get-users?role[]=${8}&role[]=10&role[]=6&hotel=${
          selectedHotel.hotelID ? selectedHotel.hotelID : 100378095
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        setHousekeepingStaff(
          _filter(
            res.data.users,
            (user) => user.active && user.roleDetails.id === 8
          )
        );
        setMaintenanceStaff(
          _filter(
            res.data.users,
            (user) => user.active && user.roleDetails.id === 6
          )
        );
        setRoomServiceStaff(
          _filter(
            res.data.users,
            (user) => user.active && user.roleDetails.id === 10
          )
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchAssignees();
  }, [selectedHotel.hotelID]);

  const handleAssignStaff = (staffID, order) => {
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/orders?id=${order.id}`,
        {
          assignedUser: staffID,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      )
      .then(() => {
        setSelectLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text: err.response.data.message,
        }).then((result) => {
          if (result.isConfirmed) {
            setSelectLoading(false);
          }
        });
      });
  };

  return (
    <Select
      disabled={selectLoading}
      style={{ width: "100%" }}
      loading={selectLoading}
      onChange={(val) => handleAssignStaff(val, row)}
      defaultValue={
        row.assignedUserDetails && row.assignedUserDetails.userProfile
          ? `${row.assignedUserDetails.userProfile.firstName} ${row.assignedUserDetails.userProfile.lastName}`
          : "Not assigned"
      }
    >
      <Select.Option disabled value="">
        Not assigned
      </Select.Option>

      {/* Show correct service staff members */}
      {row.orderType?.english === "Room Service"
        ? roomServiceStaff.map((person) => (
            <Select.Option key={person.id} value={person.id}>
              {person?.userProfile?.firstName} {person?.userProfile?.lastName}
            </Select.Option>
          ))
        : row.orderType?.english === "Housekeeping"
        ? housekeepingStaff.map((person) => (
            <Select.Option key={person.id} value={person.id}>
              {person?.userProfile?.firstName} {person?.userProfile?.lastName}
            </Select.Option>
          ))
        : maintenanceStaff.map((person) => (
            <Select.Option key={person.id} value={person.id}>
              {person?.userProfile?.firstName} {person?.userProfile?.lastName}
            </Select.Option>
          ))}
    </Select>
  );
};

export default AssignStaffSelect;
