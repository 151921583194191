import { Button, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import AddFrontOfficeItem from "./AddFrontOfficeItem";
import EditFrontOfficeItem from "./EditFrontOfficeItem";
import ChangeItemStatusButton from "../../components/ChangeItemStatusButton";

const FrontOfficeCategory = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [addItemModal, setAddItemModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/items/get-items?serviceCategory=${params.id}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setData(res.data.items);
        setLoading(false);
      });
  };

  const handleDeleteClick = (record, rowIndex) => {
    console.log(rowIndex);
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#0d375c",
      confirmButtonText: "Yes, delete it!",
      preConfirm: () => {
        return axios
          .delete(
            `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${record.id}`,
            {
              headers: {
                Authorization: `Bearer ${user.data.accessToken}`,
              },
            }
          )
          .then(() => {
            // setIsDeleting((prev) => !prev);
            fetchData();
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          });
      },
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["name", "english"],
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) =>
        [1].includes(user?.roles?.id) ? (
          <>
            <EditFrontOfficeItem item={record} refetch={fetchData} />
            <Button
              danger
              dashed="true"
              onClick={() => handleDeleteClick(record)}
            >
              Delete
            </Button>
            <ChangeItemStatusButton item={record} refetch={fetchData} />
          </>
        ) : [12].includes(user?.roles?.id) ? (
          <>
            <EditFrontOfficeItem item={record} refetch={fetchData} />
            <ChangeItemStatusButton item={record} refetch={fetchData} />
          </>
        ) : (
          <ChangeItemStatusButton item={record} refetch={fetchData} />
        ),
    },
  ];

  return (
    <div>
      {[1].includes(user?.roles?.id) ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 16,
          }}
          className="top-controls"
        >
          <Button
            onClick={() => setAddItemModal(true)}
            type="primary"
            style={{ marginRight: 16, textAlign: "right" }}
          >
            Add Item
          </Button>
        </div>
      ) : (
        ""
      )}
      <Table
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <AddFrontOfficeItem
        addItemModal={addItemModal}
        setAddItemModal={setAddItemModal}
        clickedCategory={params}
        refetch={fetchData}
      />
    </div>
  );
};

export default FrontOfficeCategory;
