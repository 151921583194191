import { Button, Form, Input, Modal } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import UploadMultipleImagesHotel from "../../components/UploadMultipleImagesHotel";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const EditHotelModal = ({ data, refetch }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();

  const [desc, setDesc] = useState("");
  const [descAR, setDescAR] = useState("");
  const [descRU, setDescRU] = useState("");

  // State for image list
  const [imageList, setImageList] = useState([]);

  const handleImagesChange = useCallback((newImages) => {
    setImageList(newImages);
  }, []);

  const areImagesModified = (newImages, originalImages) => {
    if (newImages.length !== originalImages.length) return true;
    return newImages.some((newImage, index) =>
      newImage.link !== originalImages[index]?.link ||
      newImage.fileName !== originalImages[index]?.fileName
    );
  };

  useEffect(() => {
    if (modal) {
      // Set descriptions before opening modal
      setDesc(data?.description?.english || "");
      setDescAR(data?.description?.arabic || "");
      setDescRU(data?.description?.russian || "");
      setImageList(data?.images ? data?.images : []);
      form.setFieldsValue({
        name: data?.name?.english,
        nameRU: data?.name?.russian,
        nameAR: data?.name?.arabic,
      });
    }
  }, [data, modal, form]);

  useEffect(() => {
    if (modal) {
      form.setFieldsValue({
        description: desc,
        descriptionAR: descAR,
        descriptionRU: descRU,
      });
    }
  }, [desc, descAR, descRU, modal, form]);

  const onFinish = async (values) => {
    const payload = {
      name: {
        english: values.name,
        arabic: values.nameAR,
        russian: values.nameRU,
      },
      description: {
        english: desc, // Make sure these are the updated states
        russian: descRU,
        arabic: descAR,
      },
    };

    // Add images to payload only if they’ve been modified
    if (areImagesModified(imageList, data.images || [])) {
      payload.images = imageList.map((img) => ({
        link: img.link,
        fileName: img.fileName,
      }));
    }

    setLoading(true);
    try {
      // Send the patch request
      await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotels?id=${data.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );

      // After success
      form.resetFields(); // Reset form fields
      refetch(); // Refetch data
      setModal(false); // Close the modal
    } catch (error) {
      // Handle error
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An unexpected error occurred",
      });
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleModalCancel = () => {
    form.resetFields();
    setModal(false);
  };

  return (
    <>
      <Button onClick={() => setModal(true)}>View</Button>
      <Modal
        onCancel={handleModalCancel}
        open={modal}
        destroyOnClose
        okText={"Edit Hotel"}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        centered
        confirmLoading={loading}
      >
        <Form
          form={form}
          layout="vertical"
          name="editHotelModal"
        >
          <Form.Item label="Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Name in Russian" name="nameRU">
            <Input />
          </Form.Item>
          <Form.Item label="Name in Arabic" name="nameAR">
            <Input />
          </Form.Item>
          <Form.Item name="images">
            <UploadMultipleImagesHotel data={imageList} onChange={handleImagesChange} />
            {imageList ? null : <p>Please upload at least one image</p>}
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please input a description!" }]}
          >
            <ReactQuill theme="snow" value={desc} onChange={setDesc} />
          </Form.Item>

          <Form.Item
            name="descriptionRU"
            label="Description in Russian"
            rules={[{ required: true, message: "Please input a description!" }]}
          >
            <ReactQuill theme="snow" value={descRU} onChange={setDescRU} />
          </Form.Item>

          <Form.Item
            name="descriptionAR"
            label="Description in Arabic"
            rules={[{ required: true, message: "Please input a description!" }]}
          >
            <ReactQuill theme="snow" value={descAR} onChange={setDescAR} />
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
};

export default EditHotelModal;
