import { DatePicker } from "antd";

const DateFilterDropdown = ({ dateFilter, setDateFilter }) => {
  const { RangePicker } = DatePicker;
  return (
    <div
      style={{
        padding: 24,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <RangePicker value={dateFilter} onChange={(val) => setDateFilter(val)} />
    </div>
  );
};

export default DateFilterDropdown;
