import { useEffect, useState } from "react";
import { requestPermission, onMessageListener } from "../firebase/firebase";
import { Button, Space, notification } from "antd";
import notificationSound from "../assets/audio/notification-sound.mp3";

const NotificationToast = () => {
  const [api, contextHolder] = notification.useNotification();
  const [notificationData, setNotificationData] = useState({
    message: "",
    description: "",
  });
  const key = `open${Date.now()}`;
  const btn = (
    <Space>
      <Button type="link" size="small" onClick={() => api.destroy()}>
        Close All Notifications
      </Button>
      <Button type="primary" size="small" onClick={() => api.destroy(key)}>
        Close
      </Button>
    </Space>
  );
  useEffect(() => {
    if (notificationData.message === "") return;
    api.info({
      message: notificationData.message,
      description: notificationData.description,
      btn,
      key,
      duration: 0,
      closeIcon: false,
    });
    const notifSound = new Audio(notificationSound);
    notifSound.play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationData]);

  useEffect(() => {
    requestPermission();
    const unsubscribe = onMessageListener().then((payload) => {
      setNotificationData({
        message: payload?.notification?.title,
        description: payload?.notification?.body,
      });
    });
    return () => {
      unsubscribe.catch((err) => console.log("failed: ", err));
    };
  }, [notificationData]);
  return <>{contextHolder}</>;
};
export default NotificationToast;
