function UsersIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.665"
        height="20.723"
        viewBox="0 0 16.665 20.723"
      >
        <g data-name="Iconly/Light/Profile" transform="translate(.75 .75)">
          <g
            className="menu-item-color-control"
            fill="rgba(0,0,0,0)"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          >
            <circle
              cx="4.778"
              cy="4.778"
              r="4.778"
              transform="translate(2.801)"
            ></circle>
            <path d="M0 16.201a2.215 2.215 0 01.22-.97 4.042 4.042 0 012.819-1.62 16.787 16.787 0 012.343-.326 25.053 25.053 0 014.385 0 16.979 16.979 0 012.343.33c1.071.22 2.362.659 2.819 1.62a2.27 2.27 0 010 1.95c-.458.961-1.748 1.4-2.819 1.611a15.716 15.716 0 01-2.343.339 25.822 25.822 0 01-3.567.05 4.066 4.066 0 01-.815-.055 15.423 15.423 0 01-2.334-.339C1.968 18.585.687 18.142.22 17.185a2.279 2.279 0 01-.22-.984z"></path>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default UsersIcon;
