function OutletsIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.624"
        height="15.439"
        viewBox="0 0 17.624 15.439"
      >
        <g transform="translate(-12 -6.342)">
          <g transform="translate(12 6.342)">
            <path
              fill="currentColor"
              d="M27.126-60.3v6.829a1.483 1.483 0 01-.442 1.087 1.466 1.466 0 01-1.081.444H13.21a1.457 1.457 0 01-1.081-.45 1.478 1.478 0 01-.442-1.073v-6.88a2.148 2.148 0 01-1-1.338A3.756 3.756 0 0110.7-63.4l1.053-2.94a1.183 1.183 0 01.513-.8 1.836 1.836 0 01.993-.248h12.279a1.981 1.981 0 01.986.219 1.16 1.16 0 01.52.808l1.071 2.961a3.723 3.723 0 010 1.731 2.24 2.24 0 01-.989 1.369zm-5.636-.83a1.476 1.476 0 001.036-.368.971.971 0 00.355-.841l-.623-3.811h-2.141v3.638a1.328 1.328 0 00.4.983 1.329 1.329 0 00.973.399zm-4.179 0a1.335 1.335 0 00.99-.4 1.344 1.344 0 00.4-.983v-3.637h-2.147l-.623 3.85a1 1 0 00.364.791 1.4 1.4 0 001.016.379zm-4.156 0a1.358 1.358 0 00.905-.32 1.237 1.237 0 00.444-.8l.628-3.9h-1.867a.4.4 0 00-.246.068.408.408 0 00-.137.2l-1.029 2.94a1.3 1.3 0 00.165 1.244 1.312 1.312 0 001.137.568zm12.5 0a1.382 1.382 0 001.117-.545 1.26 1.26 0 00.209-1.262l-1.05-2.963a.357.357 0 00-.137-.2.447.447 0 00-.246-.059H23.68l.628 3.9a1.219 1.219 0 00.456.8 1.371 1.371 0 00.894.329zM13.21-53.174H25.6a.284.284 0 00.209-.082.284.284 0 00.082-.209v-6.47l-.1.023a.673.673 0 01-.132.014 2.678 2.678 0 01-1.125-.243 2.57 2.57 0 01-.941-.753 2.668 2.668 0 01-.928.744 2.78 2.78 0 01-1.21.252 2.311 2.311 0 01-1.056-.25 3.935 3.935 0 01-.992-.746 2.9 2.9 0 01-1.006.746 2.745 2.745 0 01-1.114.25 3.035 3.035 0 01-1.072-.2 2.229 2.229 0 01-.975-.8 3.4 3.4 0 01-1.149.8 2.768 2.768 0 01-.935.193.545.545 0 01-.123-.014q-.059-.014-.114-.023v6.47a.284.284 0 00.082.209.284.284 0 00.209.089z"
              data-name="Path 17913"
              transform="translate(-10.593 67.382)"
            ></path>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default OutletsIcon;
