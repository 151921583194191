import { Button, Form, InputNumber, Modal } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const EditHotelRoomsModal = ({ data, refetch }) => {
  const [modal, setModal] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();
  const [existingRooms, setExistingRooms] = useState([]);
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);

  useEffect(() => {
    const fetchRooms = async () => {
      if (modal) {
        try {
          const hotelID = selectedHotel.hotelID || 100378095;
          const response = await axios.get(
            `${import.meta.env.VITE_APP_BASE_API_URL}/hotelRooms/get-rooms?hotel=${hotelID}`,
            {
              headers: {
                Authorization: `Bearer ${user.data.accessToken}`,
              },
            }
          );
          setExistingRooms(response.data.rooms);
        } catch (error) {
          console.error("Error fetching rooms: ", error);
          Swal.fire({
            icon: "error",
            title: "Failed to fetch rooms",
            text: "Unable to load existing room numbers. Please try again.",
          });
        }
      }
    };

    // Populate the form with the room number when the modal opens
    if (modal) {
      form.setFieldsValue({
        roomNo: data.roomNo, // Pre-fill the room number
      });
    }

    fetchRooms();
  }, [modal, selectedHotel.hotelID, user.data.accessToken, form, data.roomNo]);

  const onFinish = async (values) => {
    const isDuplicate = existingRooms.some((room) => {
      return parseInt(room.roomNo) === parseInt(values.roomNo) && room.id !== data.id;
    });

    if (isDuplicate) {
      Swal.fire({
        icon: "error",
        title: "Duplicate Room Number",
        text: "A room with this number already exists. Please choose a different room number.",
      });
      return;
    }

    await axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelRooms?id=${data.id}`,
        { roomNo: values.roomNo },
        { headers: { Authorization: `Bearer ${user.data.accessToken}` } }
      )
      .then(function (response) {
        form.resetFields();
        setModal(false);
        refetch();
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const handleModalCancel = () => {
    form.resetFields();
    setModal(false);
  };

  return (
    <>
      <Button onClick={() => setModal(true)}>Edit</Button>
      <Modal
        title="Edit Hotel Room"
        visible={modal}
        onCancel={handleModalCancel}
        destroyOnClose
        okText="Edit Room"
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        centered
      >
        <Form form={form} layout="vertical" name="editHotelRoom">
          <Form.Item
            label="Room Number"
            name="roomNo"
            rules={[
              {
                required: true,
                message: "Please enter a valid room number!",
              },
            ]}
          >
            <InputNumber controls={false} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditHotelRoomsModal;
