import { Button } from "antd";
import axios from "axios";
import { useState } from "react";

const ChangeItemStatusButton = ({ item, refetch }) => {
  const [active, setActive] = useState(item.active);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleDeactivate = () => {
    setActive(false); // Optimistically update the state
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
        { active: false },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(() => refetch())
      .catch((err) => {
        console.log(err);
        setActive(true); // Revert back in case of error
      });
  };

  const handleActivate = () => {
    setActive(true); // Optimistically update the state
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
        { active: true },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(() => refetch())
      .catch((err) => {
        console.log(err);
        setActive(false); // Revert back in case of error
      });
  };

  return (
    <>
      {active ? (
        <Button onClick={handleDeactivate} style={{ marginLeft: 16 }} danger>
          Deactivate
        </Button>
      ) : (
        <Button
          onClick={handleActivate}
          ghost
          style={{ marginLeft: 16 }}
          type="primary"
        >
          Activate
        </Button>
      )}
    </>
  );
};

export default ChangeItemStatusButton;
