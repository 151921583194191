function LogoutIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.537"
        height="20"
        data-name="Iconly/Light-outline/Logout"
        viewBox="0 0 20.537 20"
      >
        <g transform="translate(-.022 -.022)">
          <path
            fill="currentColor"
            d="M4.434 20A4.435 4.435 0 010 15.773V4.435A4.436 4.436 0 014.226 0H9.309a4.435 4.435 0 014.431 4.226v1.141a.75.75 0 01-1.493.1l-.007-.1v-.932A2.935 2.935 0 009.482 1.5H4.434A2.935 2.935 0 001.5 4.262v11.303A2.935 2.935 0 004.261 18.5l.173.005h4.885a2.925 2.925 0 002.92-2.752v-1.115a.751.751 0 011.494-.1l.006.1v.943A4.426 4.426 0 019.534 20h-.215zm11.894-6.555a.75.75 0 01-.07-.977l.072-.085 1.64-1.634H7.746a.75.75 0 01-.1-1.493l.1-.006h10.226L16.33 7.617a.75.75 0 01-.076-.977l.073-.085a.751.751 0 01.976-.075l.084.072 2.929 2.915a.747.747 0 01.214.436V10.069a.746.746 0 01-.073.252l-.006.013-.006.01-.008.014-.008.013-.009.014-.008.012-.007.011-.007.009a.754.754 0 01-.09.1l-2.911 2.9a.749.749 0 01-1.06 0z"
            data-name="Logout"
            transform="translate(.022 .022)"
          ></path>
        </g>
      </svg>
    </span>
  );
}

export default LogoutIcon;
