import { DatePicker } from "antd";
import { useEffect, useState } from "react";
import "../../assets/scss/Charts.scss";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import dayjs from "dayjs";
import axios from "axios";
import { useSelector } from "react-redux";

dayjs.locale('en-gb');

const CheckInOutChart = () => {
  const [chartData, setChartData] = useState([]);
  const [checkIn, setcheckIn] = useState([]);
  const [checkout, setcheckout] = useState([]);
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);

  const user = JSON.parse(localStorage.getItem("user"));

  const fetchChart = (startDate, endDate) => {
    axios
      .get(`${import.meta.env.VITE_APP_BASE_API_URL}/statistics/hotel-reservations`, {
        params: {
          hotel: selectedHotel.hotelID,
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        },
        headers: {
          Authorization: `Bearer ${user.data.accessToken}`,
        },
      })
      .then((res) => {
        console.log("res : ", res);
        const dataMap = new Map();

        // Process check-ins
        res.data.checkins.forEach(entry => {
          const dateKey = entry.fromDate; // Check-in date
          const day = dayjs(dateKey).format("dddd"); // Get day of the week
          dataMap.set(dateKey, { name: day, "Check In": Math.round(entry.count) });
        });

        // Process check-outs (use toDate)
        res.data.checkouts.forEach(entry => {
          const dateKey = entry.toDate; // Check-out date
          const existing = dataMap.get(dateKey) || { name: dayjs(dateKey).format("dddd") };
          existing["Check Out"] = Math.round(entry.count);
          dataMap.set(dateKey, existing);
        });

        // Convert map to array and update chart data
        const formattedData = Array.from(dataMap.values());
        setChartData(formattedData);
      })
      .catch((err) => console.error(err));
  };



  // Initial fetch for the current week
  useEffect(() => {
    const startOfWeek = dayjs().startOf('week');
    const endOfWeek = dayjs().endOf('week');
    fetchChart(startOfWeek, endOfWeek);
  }, [selectedHotel.hotelID]);

  return (
    <div className="checkInCheckOutChart single-chart">
      <h2>Check-In / Check-Out</h2>
      <DatePicker
        onChange={(value) => {
          const startOfWeek = value.startOf('week');
          const endOfWeek = value.endOf('week');
          fetchChart(startOfWeek, endOfWeek);
        }}
        allowClear={false}
        picker="week"
        defaultValue={dayjs()}
      />

      <BarChart
        width={500}
        height={300}
        data={chartData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis label={{ value: "No. of guests", angle: -90, position: "insideLeft" }} />
        <Tooltip />
        <Legend />
        <Bar dataKey="Check In" fill="#cb9e6b" />
        <Bar dataKey="Check Out" fill="#113b5f" />
      </BarChart>
    </div>
  );
};

export default CheckInOutChart;
