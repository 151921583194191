import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, Progress } from "antd";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from 'uuid'; // Import uuid

const UploadMultipleImagesHotel = ({ data, onChange }) => {
    const S3_BUCKET = "stella-icons";
    const REGION = "us-east-2";

    const [fileList, setFileList] = useState([]);
    const [progress, setProgress] = useState(0);

    AWS.config.update({
        accessKeyId: "AKIAYSE4N3YSOTSST6JH",
        secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    useEffect(() => {
        if (data) {
            const initializedFileList = data.map((img) => {
                const fileNameFromLink = img.link.split("/").pop(); // Extract filename from link
                return {
                    uid: img.fileName || fileNameFromLink,  // Use existing fileName or fallback to filename from link
                    name: img.fileName || fileNameFromLink, // Display name set to fileName or filename from link
                    link: img.link,
                    fileName: img.fileName || fileNameFromLink, // Ensure fileName has a fallback from link
                };
            });
            setFileList(initializedFileList);
        }
    }, [data]);



    const uploadFile = async (file) => {
        const uniqueFileName = `${uuidv4()}_${file.name}`; // Use uuid for unique filename
        const params = {
            Bucket: S3_BUCKET,
            Key: `src/${uniqueFileName}`,
            Body: file,
            ContentType: file.type,
        };

        try {
            const upload = s3.upload(params);

            // Listen for progress updates
            upload.on("httpUploadProgress", (evt) => {
                const percentage = parseInt((evt.loaded * 100) / evt.total);
                setProgress(percentage); // Update progress for the current upload
            });

            // Await the upload completion
            const data = await upload.promise();

            // Create a new file object to store in fileList
            const newFile = { uid: uniqueFileName, name: uniqueFileName, link: data.Location, fileName: uniqueFileName };

            // Use functional state update to ensure we have the latest state
            setFileList(prev => {
                const updatedList = [...prev, newFile];
                onChange(updatedList); // Notify parent component of the updated list
                return updatedList; // Return updated state
            });
        } catch (error) {
            console.error("Error uploading file:", error);
            alert("File upload failed.");
        } finally {
            setProgress(0); // Reset progress only after upload completes
        }
    };

    const deleteFile = async (fileKey) => {
        const params = { Bucket: S3_BUCKET, Key: fileKey };

        try {
            await s3.deleteObject(params).promise();
            console.log("File deleted successfully:", fileKey);

            setFileList((prev) => {
                const updatedList = prev.filter((img) => img.fileName !== fileKey.split("/").pop());
                console.log("File list after deletion:", updatedList);
                onChange(updatedList); // Notify parent of the updated images
                return updatedList; // Return updated state
            });
        } catch (error) {
            console.error("Error deleting file:", error);
            alert(`File deletion failed: ${error.message || "Unknown error"}`);
        }
    };

    const handleImageChange = async (info) => {
        const { file } = info;

        if (file.status === "done") {
            console.log("File already uploaded:", file.name);
        } else if (file.status === "removed") {
            await deleteFile(`src/${file.name}`); // Ensure correct file path is passed for deletion
        } else if (file.status === "error") {
            console.error("Upload failed:", file.name);
        } else {
            const isDuplicate = fileList.some(item => item.name === file.name);
            if (!isDuplicate) {
                await uploadFile(file.originFileObj || file);
            } else {
                console.warn(`Duplicate file not added: ${file.name}`);
            }
        }
    };

    const handleDelete = (file) => {
        const updatedFiles = fileList.filter(item => item.uid !== file.uid);
        setFileList(updatedFiles);
        onChange(updatedFiles); // Ensure this is called to propagate changes up
    };

    return (
        <>
            <Upload
                listType="picture"
                fileList={fileList.map((file) => ({
                    ...file,
                    key: file.uid, // Use uid as the key
                    status: 'done', // Set status to done for already uploaded images
                }))}
                className="upload-list-inline"
                beforeUpload={() => false}
                onChange={handleImageChange}
                onRemove={handleDelete}
            >
                <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            {progress > 0 && (
                <div style={{ marginTop: 16 }}>
                    <Progress percent={progress} status="active" />
                </div>
            )}
        </>
    );
};

export default UploadMultipleImagesHotel;
