import dayjs from "dayjs";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireAuth = ({ allowedRoles }) => {
  const auth = JSON.parse(localStorage.getItem("user"));
  // console.log(dayjs(auth?.loginDate).diff(dayjs(), "hour"));
  const location = useLocation();
  return dayjs(auth?.loginDate).diff(dayjs(), "hour") <= -8 ? (
    (localStorage.removeItem("user"), (<Navigate to={"/logout"} />))
  ) : allowedRoles.includes(auth?.roles?.id) ? (
    <Outlet />
  ) : auth?.data?.accessToken ? (
    <Navigate to={"/unauthorized"} state={{ from: location }} replace />
  ) : (
    <Navigate to={"/login"} state={{ from: location }} replace />
  );
};

export default RequireAuth;
