function OffersIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.182"
        height="20.182"
        viewBox="0 0 20.182 20.182"
      >
        <g data-name="Iconly/Light/Discount" transform="translate(.75 .933)">
          <g transform="translate(-.75 -.751)">
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M2.794 5.056a2.262 2.262 0 012.261-2.262h1.029a2.262 2.262 0 001.593-.657l.719-.72a2.262 2.262 0 013.2-.008l.009.008.72.72a2.258 2.258 0 001.593.657h1.028a2.262 2.262 0 012.262 2.262v1.027a2.26 2.26 0 00.657 1.594l.72.72a2.262 2.262 0 01.009 3.2l-.009.009-.72.72a2.256 2.256 0 00-.657 1.592v1.029a2.261 2.261 0 01-2.262 2.261h-1.03a2.259 2.259 0 00-1.593.658l-.72.719a2.261 2.261 0 01-3.2.009l-.009-.009-.719-.719a2.263 2.263 0 00-1.593-.658H5.055a2.261 2.261 0 01-2.261-2.261v-1.031a2.257 2.257 0 00-.658-1.592l-.719-.72a2.261 2.261 0 01-.009-3.2l.009-.009.719-.72a2.261 2.261 0 00.658-1.594V5.056"
              data-name="Stroke 1"
            ></path>
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M7.43 12.57l5.14-5.14"
              data-name="Stroke 3"
            ></path>
            <path
              fill="currentColor"
              d="M.75 1.49a.749.749 0 01-.53-.22.821.821 0 01-.16-.25A.668.668 0 010 .74.717.717 0 01.06.45.776.776 0 01.22.21a.774.774 0 011.06 0 .807.807 0 01.17.24.929.929 0 01.05.29.863.863 0 01-.05.28.852.852 0 01-.17.25.749.749 0 01-.53.22"
              data-name="Fill 5"
              transform="translate(11.817 11.832)"
            ></path>
            <path
              fill="currentColor"
              d="M.75 1.491a.672.672 0 01-.28-.061.818.818 0 01-.25-.159.963.963 0 01-.16-.25.674.674 0 01-.06-.28.714.714 0 01.06-.29.674.674 0 01.16-.24.772.772 0 011.06 0 .748.748 0 01.22.53.666.666 0 01-.05.28 1.006 1.006 0 01-.17.25.773.773 0 01-.24.159.718.718 0 01-.29.061"
              data-name="Fill 7"
              transform="translate(6.677 6.692)"
            ></path>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default OffersIcon;
