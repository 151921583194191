import { Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import EditHotelModal from "./EditHotelModal";

const Hotels = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const auth = JSON.parse(localStorage.getItem("user"));
  const columns = [
    {
      title: "Name",
      dataIndex: ["name", "english"],
      key: "name",
    },
    {
      title: "Country",
      dataIndex: ["country", "english"],
      key: "country",
    },
    {
      title: "City",
      dataIndex: ["city", "english"],
      key: "city",
    },

    {
      title: "Action",
      key: "actions",
      render: (record) => (
        <EditHotelModal refetch={fetchHotels} data={record} />
      ),
    },
  ];

  const fetchHotels = () => {
    setLoading(true);
    axios
      .get(`${import.meta.env.VITE_APP_BASE_API_URL}/hotels/get-hotels`, {
        headers: {
          Authorization: `Bearer ${auth.data.accessToken}`,
        },
      })
      .then((res) => {
        setTableData(res.data.hotels);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchHotels();
  }, []);

  //

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={tableData}
      />
    </div>
  );
};

export default Hotels;
