import { Button, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Amenities = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/hotelAmenities/get-amenities?hotel=${
          selectedHotel.hotelID ? selectedHotel.hotelID : 100378095
        }`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        const formattedData = res.data.amenities.map((amenity) => ({
          id: amenity.id,
          name: amenity.name.english,
          active: amenity.active,
          // Add any other fields you might need
        }));
        setData(formattedData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleDeactivate = (item) => {
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelamenities?id=${item}`,
        { active: false },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(() => fetchData())
      .catch((err) => console.log(err));
  };

  const handleActivate = (item) => {
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelamenities?id=${item}`,
        { active: true },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(() => fetchData())
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Icon",
    },
    {
      title: "Status",
      dataIndex: "active",
      render: (status, row) =>
        status ? (
          <Button onClick={() => handleDeactivate(row.id)} danger>
            Deactivate
          </Button>
        ) : (
          <Button onClick={() => handleActivate(row.id)} ghost type="primary">
            Activate
          </Button>
        ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, [selectedHotel]);

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={data}
      />
    </div>
  );
};

export default Amenities;
