import { useState, useEffect } from "react";
import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from 'uuid'; // Import uuid

const UploadItemIcon = ({ value, onChange }) => {
  const S3_BUCKET = "stella-icons";
  const REGION = "us-east-2";

  // Configure AWS SDK
  AWS.config.update({
    accessKeyId: "AKIAYSE4N3YSOTSST6JH",
    secretAccessKey: "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",
  });

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const [imageURL, setImageURL] = useState(value || "");
  // Inside UploadItemIcon component
  useEffect(() => {
    if (value) {
      setImageURL(value); // Update imageURL if value is provided
    }
  }, [value,imageURL]); // Runs every time the `value` prop changes



  const uploadFile = async (file) => {
    const uniqueFileName = `${uuidv4()}_${file.name}`; // Use uuid for unique filename
    const params = {
      Bucket: S3_BUCKET,
      Key: `src/${uniqueFileName}`,
      Body: file,
      ContentType: file.type,
    };

    try {
      const data = await s3.upload(params).promise();
      console.log("File uploaded successfully:", data.Location); // Log the uploaded file URL
      return data.Location; // Return only the URL
    } catch (error) {
      console.error("Error in uploadFile:", error); // Log any upload errors
      Swal.fire({
        icon: "error",
        title: "Upload failed",
        text: "File upload failed. Please try again.",
      });
      return null;
    }
  };



  const handleImageChange = async ({ file }) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You can only upload JPG/PNG file!",
      });
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Image must be smaller than 2MB!",
      });
      return;
    }

    const uploadedImageURL = await uploadFile(file.originFileObj || file);
    if (uploadedImageURL) {
      setImageURL(uploadedImageURL);
      onChange(uploadedImageURL); // Pass only the URL string to parent
    }
  };



  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Upload
      name="avatar"
      listType="picture-circle"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={() => false}
      onChange={handleImageChange}
    >
      {imageURL ? (
        <img
          src={imageURL}
          alt="avatar"
          style={{
            width: "100%",
            borderRadius: "50%",
          }}
        />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default UploadItemIcon;
