import { Form, Input, Modal } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import UploadItemIcon from "./UploadIcon";
import { useState } from "react";

const AddServiceCategory = ({
  setAddCategoryModal,
  addCategoryModal,
  serviceID,
  refetch,
}) => {

  const [imageList, setImageList] = useState([]); // Initialize as empty array

  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const onFinish = async (values) => {

    console.log(values.categoryId);
    console.log("Success:", values);
    await axios
      .post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/serviceCategories`,
        {
          name: {
            english: values.categName,
            arabic: values.categNameAR,
            russian: values.categNameRU,
          },
          description: {
            english: "desc",
            arabic: "descArabic",
            russian: "descRussian",
          },
          service: serviceID,
          active: true,
          categoryId: values.categoryId? values.categoryId : null,
          images: imageList.map((img) => ({
            fileName: img.fileName || img.link.split('/').pop(), // Make sure to correctly get fileName
            link: img.link,
          })),
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(function (response) {
        form.resetFields();
        console.log(response);
        refetch();
        setAddCategoryModal(false);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setAddCategoryModal(false);
  };
  return (
    <Modal
      okText={"Add Category"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={addCategoryModal}
      centered
    >
      <UploadItemIcon
        onChange={(imageURL) => {
          console.log("Uploaded Image URL:", imageURL);
          const newImage = {
            fileName: imageURL.split('/').pop(),
            link: imageURL,
          };
          setImageList([newImage, ...imageList]); // Update image list
        }}
      />
      {<br></br>}
      <Form form={form} layout="vertical" name="add-category-form">
        <Form.Item
          name="categName"
          label="Category Name"
          rules={[
            {
              required: true,
              message: "Please input the category name!",
            },
            {
              whitespace: true,
              message: "Please input a valid category name!",
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="categNameRU"
          label="Category Name in Russian"
          rules={[
            {
              required: true,
              message: "Please input the category name!",
            },
            {
              whitespace: true,
              message: "Please input a valid category name!",
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="categNameAR"
          label="Category Name in Arabic"
          rules={[
            {
              required: true,
              message: "Please input the category name!",
            },
            {
              whitespace: true,
              message: "Please input a valid category name!",
            }
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>
        <Form.Item
          name="categoryId"
          label="Category ID"

        >
          <Input type="number" min={1} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default AddServiceCategory;
