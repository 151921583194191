import { Form, InputNumber, Modal } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";

const CreateHotelRoomModal = ({ createModal, setCreateModal, refetch }) => {
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const [existingRooms, setExistingRooms] = useState([]);

  // Fetch existing rooms when the modal is opened to check for duplicates
  useEffect(() => {
    if (createModal) {
      axios
        .get(
          `${import.meta.env.VITE_APP_BASE_API_URL}/hotelRooms/get-rooms?hotel=${selectedHotel.hotelID ? selectedHotel.hotelID : 100378095
          }`,
          {
            headers: {
              Authorization: `Bearer ${user.data.accessToken}`,
            },
          }
        )
        .then((res) => {
          setExistingRooms(res.data.rooms);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [createModal]);

  const onFinish = async (values) => {
    // Check if the room number already exists
    const isDuplicate = existingRooms.some(
      (room) => room.roomNo === values.roomNo
    );

    if (isDuplicate) {
      Swal.fire({
        icon: "error",
        title: "Duplicate Room Number",
        text: "A room with this number already exists. Please choose a different room number.",
      });
      return;
    }

    // Proceed with the room creation if no duplicate is found
    await axios
      .post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelRooms`,
        {
          roomNo: values.roomNo,
          active: true,
          hotel: selectedHotel.hotelID ? selectedHotel.hotelID : 100378095,
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(function (response) {
        form.resetFields();
        refetch(); // Refetch the rooms list
        setCreateModal(false); // Close the modal
        Swal.fire("Success", "Room added successfully", "success");
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setCreateModal(false);
  };

  return (
    <Modal
      okText={"Add Room"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={createModal}
      centered
    >
      <Form form={form} layout="vertical" name="add-hotel-room-form">
        <Form.Item
          name="roomNo"
          label="Room Number"
          rules={[
            {
              required: true,
              message: "Please input the Room Number!",
            },
          ]}
        >
          <InputNumber controls={false} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateHotelRoomModal;
