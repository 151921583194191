import { Form, Input, Modal } from "antd";
import axios from "axios";
import UploadItemIcon from "../../components/UploadIcon";
import Swal from "sweetalert2";
import { useState } from "react";

const ActivitiesFacilitiesCreateItem = ({
  setAddItemModal,
  addItemModal,
  clickedCategory,
  refetch,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const onFinish = async (values) => {
    setLoading(true);
    console.log("Success:", values);
    console.log(values.icon);
    await axios
      .post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items`,
        {
          icon: values.icon ,

          name: {
            english: values.itemName,
            arabic: values.itemNameAR,
            russian: values.itemNameRU,
          },
          description: {
            english: values.description,
            arabic: values.descriptionAR,
            russian: values.descriptionRU,
          },
          extraFields: {
            openingHours: values.openingHours,
            phone: values.phone,
          },
          timer: 0,
          price: 0,
          serviceCategory: clickedCategory.id,

          serviceStandaredID: values?.serviceStandaredID?values?.serviceStandaredID:null,

        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(function (response) {
        form.resetFields();
        console.log(response);
        refetch();
        setAddItemModal(false);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    form.resetFields();

    setAddItemModal(false);
  };
  return (
    <Modal
      confirmLoading={loading}
      okText={"Create item"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={addItemModal}
      centered
    >
      <Form form={form} layout="vertical" name="addServiceItemModal">
        <Form.Item label="Item Icon" name="icon" rules={[{
              required: true,
              message: "Please input the item icon!",
            },]}>
          <UploadItemIcon />
        </Form.Item>
        <Form.Item
          name="itemName"
          label="Item Name"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
            {
              whitespace: true,
              message: "Please input a valid item name!",
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameRU"
          label="Item Name in Russian"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
            {
              whitespace: true,
              message: "Please input a valid item name!",
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameAR"
          label="Item Name in Arabic"
          rules={[
            {
              required: true,
              message: "Please input the item name!",
            },
            {
              whitespace: true,
              message: "Please input a valid item name!",
            }
          ]}
        >
          <Input dir="rtl" />
        </Form.Item>
        <Form.Item label="Description" name="description" rules={[
          {
            whitespace: true,
            message: "Please input a valid description!",
          },
        ]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Description in Russian" name="descriptionRU" rules={[
          {
            whitespace: true,
            message: "Please input a valid description!",
          }
        ]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Description in Arabic" name="descriptionAR" rules={[
          {
            whitespace: true,
            message: "Please input a valid description!",
          }
        ]}>
          <Input.TextArea dir="rtl" />
        </Form.Item>
        <Form.Item label="Phone Number" name="phone" type="number" rules={[
          {
            whitespace: true,
            message: "Please input a valid Phone Number!",
            len: 11,
          },
        ]}>
          <Input />
        </Form.Item>
        <Form.Item label="Opening Hours" name="openingHours" rules={[
          {
            whitespace: true,
            message: "Please input a valid Opening Hours!",
          }
        ]}>
          <Input />
        </Form.Item>
        <Form.Item
            name="serviceStandaredID"
            label="serviceStandared ID "
          >
            <Input type="number" min={1} />
          </Form.Item>
      </Form>
    </Modal>
  );
};

export default ActivitiesFacilitiesCreateItem;
