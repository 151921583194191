import { Select, message } from "antd";
import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

const OrderStatusSelect = ({ status, row, user, refetch }) => {
  const [currentStatus, setCurrentStatus] = useState(status);
  const [selectLoading, setSelectLoading] = useState(false);

  const handleOrderStatusChange = async (val) => {
    if (val !== currentStatus) {
      setSelectLoading(true);
      try {
        await axios.patch(
          `${import.meta.env.VITE_APP_BASE_API_URL}/orders?id=${row.id}`,
          {
            status: val,
          },
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );
        setCurrentStatus(val);
        setSelectLoading(false);
        message.success("Order status updated successfully");

        // Optionally refetch data
        if (refetch) refetch();
      } catch (err) {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text:
            err.response?.data?.message ||
            "Failed to update order status.",
        }).then(() => {
          setSelectLoading(false);
        });
      }
    }
  };

  return (
    <Select
      disabled={selectLoading}
      style={{ width: "100%" }}
      loading={selectLoading}
      onChange={handleOrderStatusChange}
      value={currentStatus}
    >
      <Select.Option value="pending">Pending</Select.Option>
      <Select.Option value="inProgress">In Progress</Select.Option>
      <Select.Option value="done">Done</Select.Option>
      <Select.Option value="cancelled">Cancelled</Select.Option>
    </Select>
  );
};

export default OrderStatusSelect;
