import { Form, Input, InputNumber, Modal } from "antd";
import axios from "axios";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import UploadItemIcon from "./UploadIcon";

const EditOutletServiceItem = ({
  setEditItemModal,
  editItemModal,
  item,
  refetch,
}) => {
  const [editForm] = Form.useForm();
  const [desc, setDesc] = useState("");
  const [descAR, setDescAR] = useState("");
  const [descRU, setDescRU] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const [image, setImage] = useState("");

  // Effect to initialize form values when item changes
  useEffect(() => {
    refetch
    if (item) {
      setImage(item.icon || ""); // Set the image URL from the fetched item or empty if not present
      setDesc(item.description?.english || "");
      setDescAR(item.description?.arabic || "");
      setDescRU(item.description?.russian || "");
      editForm.setFieldsValue({
        itemName: item.name?.english,
        itemNameRU: item.name?.russian,
        itemNameAR: item.name?.arabic,
        itemPrice: item.price,
        icon: item.icon // Ensure the icon field is set
      });
    }
  }, [item, editForm]); // Dependency on item and editForm

  const onFinish = async (values) => {
    const payload = {
      name: {
        english: values.itemName || item.name?.english,
        arabic: values.itemNameAR || item.name?.arabic,
        russian: values.itemNameRU || item.name?.russian,
      },
      description: {
        english: desc,
        arabic: descAR,
        russian: descRU,
      },
      price: values.itemPrice || item.price,
      icon: image, // Use updated image URL
    };

    try {
      await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
      refetch();
      setEditItemModal(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };

  const handleCancel = () => {
    editForm.resetFields();
    setEditItemModal(false);
  };

  return (
    <Modal
      okText={"Edit Item"}
      onOk={() => {
        editForm
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validation Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={editItemModal}
      centered
    >
      <Form
        form={editForm}
        layout="vertical"
        name="edit-service-item-modal"
      >
        <Form.Item name="icon">
          <UploadItemIcon
            value={image}
            onChange={(imageURL) => {
              setImage(imageURL); // Update image with the uploaded URL
            }}
          />
        </Form.Item>
        <Form.Item
          name="itemName"
          label="Item Name"
          rules={[{ required: true, message: "Please input the item name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameRU"
          label="Item Name in Russian"
          rules={[{ required: true, message: "Please input the item name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="itemNameAR"
          label="Item Name in Arabic"
          rules={[{ required: true, message: "Please input the item name!" }]}
        >
          <Input dir="rtl" />
        </Form.Item>

        {/* English Description */}
        <Form.Item
          label="Item Description"
          rules={[{ required: true, message: "Please input the item description!" }]}
        >
          <ReactQuill theme="snow" value={desc} onChange={setDesc} />
        </Form.Item>

        {/* Russian Description */}
        <Form.Item
          label="Item Description in Russian"
          rules={[{ required: true, message: "Please input the item description!" }]}
        >
          <ReactQuill theme="snow" value={descRU} onChange={setDescRU} />
        </Form.Item>

        {/* Arabic Description */}
        <Form.Item
          label="Item Description in Arabic"
          rules={[{ required: true, message: "Please input the item description!" }]}
        >
          <ReactQuill theme="snow" value={descAR} onChange={setDescAR} />
        </Form.Item>

        <Form.Item
          name="itemPrice"
          label="Item Price"
          rules={[{ type: "number", required: true, message: "Please input the price!" }]}
        >
          <InputNumber style={{ width: "100%" }} controls={false} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditOutletServiceItem;
