import { useLocation, Navigate } from "react-router-dom";

const Logout = () => {
  localStorage.removeItem("user");

  const location = useLocation();

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default Logout;
