import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { DatePicker, Spin } from "antd";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import "../../assets/scss/Charts.scss";

const COLORS = ["#cb9e6b", "#113b5f", "#246194", "#ffca28"];  // Added another color for the new service

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ServiceCountersChart = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [chartData, setChartData] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [dateRange, setDateRange] = useState([
    dayjs().format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ]);

  const { RangePicker } = DatePicker;

  const fetchChart = async () => {
    try {
      setChartLoading(true);
      const response = await axios.get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/statistics/services-orders?hotel=${selectedHotel.hotelID ? selectedHotel.hotelID : 100378095}&startDate=${dateRange[0]}&endDate=${dateRange[1]}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );

      const temp = [
        { name: "Room Service", value: response.data.roomServiceOrders || 0 },
        { name: "Maintenance", value: response.data.maintenanceServiceOrders || 0 },
        { name: "Housekeeping", value: response.data.housekeepingOrders || 0 },
        { name: "Front Service", value: response.data.frontOfficeServiceOrders || 0 }, // Corrected the key here
      ];
      
      setChartData(temp);
    } catch (error) {
      console.error("Error fetching chart data:", error);
    } finally {
      setChartLoading(false);
    }
  };

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul className="colors-count">
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            <span
              className="entry-color"
              style={{
                backgroundColor: entry.color,
                width: 10,
                height: 10,
                display: "inline-block",
              }}
            ></span>
            {entry.value} : {entry.payload?.value}
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    fetchChart();
  }, [selectedHotel.hotelID, dateRange]);

  return (
    <div
      className="serviceCountersChart single-chart"
      style={{
        display: "flex",
        gap: 10,
        alignItems: "center",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
    >
      <h2>Service Counter</h2>
      <RangePicker
        allowClear={false}
        onChange={(_, dateString) => setDateRange(dateString)}
        value={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
      />
      {chartLoading ? (
        <Spin
          size="medium"
          style={{
            width: 300,
            height: 250,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      ) : (
        <div className="chart">
          <PieChart width={300} height={250}>
            <Legend
              layout="horizontal"
              verticalAlign="bottom"
              align="center"
              content={renderLegend}
            />
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </div>
      )}
    </div>
  );
};

export default ServiceCountersChart;
