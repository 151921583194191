import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentOrderService: "",
  orderItems: [],
};

export const orderSlice = createSlice({
  name: "orderList",
  initialState,
  reducers: {
    setService: (state, action) => {
      state.currentOrderService = action.payload;
    },
    resetOrder: (state) => {
      state.currentOrderService = "";
      state.orderItems = [];
    },
    addItem: (state, action) => {
      state.orderItems.push({
        comment: action.payload.comment,
        quantity: action.payload.quantity,
        item: action.payload.item,
      });
    },
    removeItem: (state, action) => {
      state.orderItems.splice(action.payload, 1);
    },
    incrementItemQuantity: (state, action) => {
      state.orderItems[action.payload].quantity++;
    },
    decrementItemQuantity: (state, action) => {
      state.orderItems[action.payload].quantity--;
    },
    changeItemDescription: (state, action) => {
      state.orderItems[action.payload.ix].description =
        action.payload.description;
    },

    // Lesa removie item we edit quantity
  },
});

// Action creators are generated for each case reducer function
export const {
  setService,
  resetOrder,
  addItem,
  removeItem,
  incrementItemQuantity,
  changeItemDescription,
} = orderSlice.actions;

export default orderSlice.reducer;