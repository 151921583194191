import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const AvailableRoomsChart = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [chartData, setChartData] = useState([]);

  const fetchChart = () => {
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelRooms/get-rooms?hotel=${selectedHotel.hotelID ? selectedHotel.hotelID : 100378095}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        // Count available and unavailable rooms
        const availableRooms = res.data.rooms.filter(room => room.active).length;
        const unavailableRooms = res.data.rooms.filter(room => !room.active).length;

        // Set chart data
        let temp = [
          {
            name: "Available Rooms",
            value: availableRooms,
          },
          {
            name: "Unavailable Rooms",
            value: unavailableRooms,
          },
        ];
        setChartData(temp);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchChart();
  }, [selectedHotel.hotelID]);

  const COLORS = ["#cb9e6b", "#113b5f"];

  return (
    <div
      className="availableRoomsChart"
      style={{
        display: "flex",
        gap: 10,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div className="chart single-chart">
        <h2>Available Rooms</h2>
        <PieChart width={300} height={250}>
          <Pie
            dataKey="value"
            data={chartData}
            cx="50%"
            cy="50%"
            outerRadius={80}
            label
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>
    </div>
  );
};

export default AvailableRoomsChart;
