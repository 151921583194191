import { Button, Form, Input, InputNumber, Modal } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import UploadItemIcon from "../../components/UploadIcon";
import ReactQuill from "react-quill";

const EditRoomServiceItem = ({ item, refetch }) => {
  const [editItemModal, setEditItemModal] = useState(false);
  const [editForm] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));

  // States for descriptions
  const [desc, setDesc] = useState("");
  const [descAR, setDescAR] = useState("");
  const [descRU, setDescRU] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    setImageUrl(item?.icon); // Update imageUrl with item.icon
    if (editItemModal) {
      setDesc(item?.description?.english);
      setDescAR(item?.description?.arabic);
      setDescRU(item?.description?.russian);

    }
  }, [item, editItemModal, imageUrl]);

  const onFinish = async (values) => {
    const requestData = {
      name: {
        english: values.itemName || item?.name?.english,
        arabic: values.itemNameAR || item?.name?.arabic,
        russian: values.itemNameRU || item?.name?.russian,
      },
      description: {
        english: desc || item?.description?.english,
        arabic: descAR || item?.description?.arabic,
        russian: descRU || item?.description?.russian,
      },
      price: values.itemPrice || item?.price,
      icon: typeof values.icon === 'string' ? values.icon : item.icon, // Use the new image if uploaded, otherwise keep the old
      timer: item.timer, // Assuming this value is static; adjust if needed
      active: item.active, // Assuming this value is static; adjust if needed
      serviceStandaredID : values.serviceStandaredID?Number(values.serviceStandaredID):null,

    };

    console.log("Request Data:", requestData);

    try {
      const response = await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
      console.log("Response:", response.data);
      refetch();
      setEditItemModal(false);
    } catch (error) {
      console.error("Error updating item:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong!",
      });
    }
  };

  const handleCancel = () => {
    editForm.resetFields();
    setEditItemModal(false);
  };

  return (
    <>
      <Button
        style={{ marginRight: 16 }}
        onClick={() => {
          console.log("Item before modal opens:", item); // Log item
          setEditItemModal(true);
        }}
      >
        View
      </Button>
      <Modal
        okText={"Edit Item"}
        onOk={() => {
          editForm
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        onCancel={handleCancel}
        open={editItemModal}
        centered
      >
        <Form
          form={editForm}
          initialValues={{
            itemPrice: item?.price,
            itemName: item?.name?.english,
            itemNameAR: item?.name?.arabic,
            itemNameRU: item?.name?.russian,
            icon:imageUrl,
            serviceStandaredID : item?.serviceStandaredID,

          }}
          layout="vertical"
        >
          <Form.Item name="icon" label="Upload Icon">
            <UploadItemIcon
              value={imageUrl} // Ensure you're using the correct image URL
              onChange={(imageURL) => {
                editForm.setFieldsValue({ icon: imageURL });
                console.log("New imageURL set from UploadItemIcon:", imageURL); // Log new image URL
              }}
            />
          </Form.Item>
          <Form.Item
            name="itemName"
            label="Item Name (English)"
            rules={[{ required: true, message: 'Please input the item name in English!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="itemNameAR"
            label="Item Name (Arabic)"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="itemNameRU"
            label="Item Name (Russian)"
          >
            <Input />
          </Form.Item>

          {/* English Description */}
          <Form.Item
            label="Description (English)"
            rules={[{ required: true, message: 'Please input the description in English!' }]}
          >
            <ReactQuill theme="snow" value={desc} onChange={setDesc} />
          </Form.Item>

          {/* Arabic Description */}
          <Form.Item
            label="Description (Arabic)"
          >
            <ReactQuill theme="snow" value={descAR} onChange={setDescAR} />
          </Form.Item>

          {/* Russian Description */}
          <Form.Item
            label="Description (Russian)"
          >
            <ReactQuill theme="snow" value={descRU} onChange={setDescRU} />
          </Form.Item>

          <Form.Item
            name="itemPrice"
            label="Item Price"
            rules={[{ required: true, message: 'Please input the item price!' }]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            name="serviceStandaredID"
            label="serviceStandared ID "
          >
            <Input type="number" min={1} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditRoomServiceItem;
