import { Form, Modal, Select } from "antd";
import axios from "axios";
import Swal from "sweetalert2";

const DeleteServiceCategory = ({
  setDeleteCategoryModal,
  deleteCategoryModal,
  categories,
  refetch,
}) => {
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const onFinish = async (values) => {
    console.log("Success:", values);
    await axios
      .delete(
        `${import.meta.env.VITE_APP_BASE_API_URL}/serviceCategories?id=${
          values.categName
        }`,

        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        form.resetFields();
        refetch();
        setDeleteCategoryModal(false);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const handleCancel = () => {
    setDeleteCategoryModal(false);
    form.resetFields();
  };
  return (
    <Modal
      okText={"Delete Category"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={deleteCategoryModal}
      destroyOnClose
      centered
      okButtonProps={{ danger: true, type: "dashed" }}
    >
      <Form form={form} layout="vertical" name="delete-category-form">
        <Form.Item
          name="categName"
          label="Select Category"
          rules={[
            {
              required: true,
              message: "Please input the category name!",
            },
          ]}
        >
          <Select>
            {categories?.map((categ) => (
              <Select.Option key={categ.id} value={categ.id}>
                {categ?.name?.english}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeleteServiceCategory;
