import {
  Button,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Result,
  Select,
  Table,
  Tabs,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  removeItem,
  resetOrder,
  setService,
} from "../../store/slices/orderSlice";
import Swal from "sweetalert2";
import axios from "axios";
import { PartitionOutlined } from "@ant-design/icons";

const CreateOrderMenu = ({ setAddModalOpen, addModalOpen, refetch }) => {
  const [form] = Form.useForm();
  const [target, setTarget] = useState("room");
  const [postingOrder, setPostingOrder] = useState(false);
  const [hotelRooms, setHotelRooms] = useState([]);
  const [hotelAreas, setHotelAreas] = useState([]);
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const user = JSON.parse(localStorage.getItem("user"));
  const orderState = useSelector((state) => state.orderReducer);

  const [selectedService, setSelectedService] = useState();
  const dispatch = useDispatch();

  const mainCategories = [
    "Room Service",
    "Housekeeping",
    "Maintenance",
    "Front Office",
  ];

  const dubaiCarCharging = {
    id: 114,
    name: "Car Charging",

    serviceCategories: [
      {
        id: 135,
        name: "Car Charging",
        active: true,
        items: [
          {
            id: 119,
            name: "Car Charging",
            price: 0,
          },
        ],
      },
    ],
  };

  const fetchHotelRooms = async () => {
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelRooms/get-rooms?hotel=${selectedHotel.hotelID || 100378095}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
  
      // Filter rooms to include only those that are activated and sort by room number
      const activatedRooms = res.data.rooms
        .filter(room => room.active)
        .sort((a, b) => a.roomNo - b.roomNo); // Sorting numerically by room number
  
      setHotelRooms([...activatedRooms]); // Ensure React re-renders with sorted rooms
    } catch (err) {
      console.error("Error fetching hotel rooms:", err);
    }
  };
  

  const fetchHotelAreas = async () => {
    try {
      const res = await axios.get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelareas/get-areas?hotel=${selectedHotel.hotelID || 100378095}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
  
      // Filter areas to include only activated ones and sort alphabetically
      const activatedAreas = res.data.areas
        .filter(area => area.active)
        .sort((a, b) => 
          a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase())
        );
  
      // Update the state with sorted areas
      setHotelAreas([...activatedAreas]); // Ensures React re-renders with new state
    } catch (err) {
      console.error("Error fetching hotel areas:", err);
    }
  };
  
  


  useEffect(() => {
    fetchHotelAreas();
    fetchHotelRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHotel.hotelID]);

  const handleSelectService = (clickedService) => {
    if (
      orderState.currentOrderService === "" ||
      orderState.currentOrderService === clickedService.name ||
      orderState.orderItems.length === 0
    ) {
      dispatch(setService(clickedService.name));
      setSelectedService(clickedService);
    } else {
      Swal.fire({
        title: `${orderState.currentOrderService} already selected`,
        confirmButtonText: "Clear Order",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          dispatch(resetOrder());
          dispatch(setService(clickedService));
          setSelectedService(clickedService);
        }
      });
    }
  };
  const handleSubmit = () => {

    let formData = form.getFieldsValue();
  
    // Check if either room number or area is provided
    if (!formData.roomNo && !formData.area) {
      Swal.fire({
        title: "Room number or Area are required",
        icon: "error",
        confirmButtonText: "OK",
      });
      return; // Stop further execution if no roomNo or area
    }
  
    if (orderState.orderItems.length !== 0) {
      const orderData = {
        items: orderState.orderItems.map((item) => {
          if (item.comment !== "") {
            return {
              quantity: item.quantity,
              comment: item.comment,
              id: item.item.id,
            };
          } else {
            return {
              quantity: item.quantity,
              id: item.item.id,
            };
          }
        }),
        roomNo: formData.roomNo,
        area: formData.area,
        hotel: selectedHotel.hotelID ? selectedHotel.hotelID : 100378095,
        comment: formData.comment,
      };
  
      setPostingOrder(true);
      axios
        .post(`${import.meta.env.VITE_APP_BASE_API_URL}/orders`, orderData, {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        })
        .then(() => {
          refetch();
          dispatch(resetOrder());
          form.resetFields();
          setAddModalOpen(false);
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setPostingOrder(false);
        });
    }
  };
  
  const handleCancel = () => {
    dispatch(resetOrder());
    setAddModalOpen(false);
  };

  return (
    <Modal
      okText={"Create Order"}
      onOk={handleSubmit}
      onCancel={handleCancel}
      width={"80%"}
      open={addModalOpen}
      centered
      confirmLoading={postingOrder}
    >
      <div className="order-menu-wrapper">
        <div className="menu">
          <Flex
            gap={10}
            style={{ paddingLeft: 30, paddingRight: 30, marginBottom: 30 }}
            vertical={false}
          >
            {selectedHotel?.services?.map((service) => {
              if (mainCategories.includes(service?.name)) {
                return (
                  <Button
                    onClick={() => handleSelectService(service)}
                    style={
                      orderState.currentOrderService === service.name
                        ? { color: "red", width: 240 }
                        : { width: 240 }
                    }
                    key={service.id + service.name}
                  >
                    {service.name}
                  </Button>
                );
              }
            })}
            {/* {selectedHotel.hotelID === 100378095 ? (
              <Button
                onClick={() => setSelectedService(dubaiCarCharging)}
                style={{ width: 240 }}
              >
                Car Charging
              </Button>
            ) : (
              ""
            )} */}
          </Flex>
          <Form
            scrollToFirstError
            form={form}
            layout="vertical"
            name="addOrderModal"
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select a target area/room number!",
                },
              ]}
              name="target"
              initialValue={"room"}
            >
              <Radio.Group onChange={(e) => setTarget(e.target.value)}>
                <Radio value={"room"}>Room</Radio>
                <Radio value={"area"}>Area</Radio>
              </Radio.Group>
            </Form.Item>
            <Flex justify="space-between">
              {target === "area" ? (
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please select an area!",
                    },
                  ]}
                  style={{ width: "30%" }}
                  label="Area"
                  name="area"
                >
                  <Select>
                    {hotelAreas.map((area) => (
                      <Select.Option value={area.id} key={area.id}>
                        {area.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  style={{ width: "30%" }}
                  label="Room Number"
                  name="roomNo"
                  rules={[
                    {
                      required: true,
                      message: "Please select a target room number!",
                    },
                  ]}
                >
                  <Select>
                    {hotelRooms.map((room) => (
                      <Select.Option value={room.roomNo} key={room.id}>
                        {room.roomNo}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                style={{ width: "50%" }}
                label="Comment"
                name="comment"
              >
                <Input.TextArea rows={1} autoSize />
              </Form.Item>
            </Flex>
          </Form>

          {selectedService !== undefined ? (
            <>
              <SelectedService service={selectedService} />
              Order Summary
              {orderState?.orderItems.map((item, ix) => (
                <div
                  style={{ display: "flex", gap: 10, alignItems: "center" }}
                  key={`${item?.item?.id}-${ix}`}
                >
                  <p>
                    {item?.quantity}x - {item?.item?.name} - {item?.comment}
                  </p>

                  <Button
                    shape="circle"
                    size="small"
                    danger
                    onClick={() => dispatch(removeItem(ix))}
                  >
                    X
                  </Button>
                </div>
              ))}
            </>
          ) : (
            <Result
              icon={<PartitionOutlined />}
              title="No service selected, please select a service from above"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CreateOrderMenu;

const SelectedService = ({ service }) => {
  const tabItems = service?.serviceCategories?.map((categ) => {
    if (categ.items.length !== 0)
      return {
        key: categ.id,
        label: categ.name,
        children: <SelectedCategory service={service} categ={categ} />,
      };
  });

  return (
    <div>
      <Flex gap={10} vertical={false}>
        <Tabs
          items={tabItems}
          tabPosition="left"
          style={{
            height: 320,
            width: "100%",
          }}
          moreIcon
        />
      </Flex>
    </div>
  );
};

const SelectedCategory = ({ categ, service }) => {
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Item",
      dataIndex: ["name"],
      key: "name",
    },
    ...(service?.name === "Room Service"
      ? [
          {
            title: "Price",
            dataIndex: "price",
            key: "price",
          },
        ]
      : []),
    {
      title: "Quantity",
      key: "quantity",
      render: (record) => (
        <Flex gap={20}>
          <div className="quantity">
            <Button onClick={() => handleQuantityClick("-", record)}>-</Button>
            <InputNumber
              name={`quantity-${record.id}`}
              defaultValue={1}
              readOnly
              style={{
                pointerEvents: "none",
                width: "40px",
                textAlign: "center",
              }}
              bordered={false}
              controls={false}
            />
            <Button onClick={() => handleQuantityClick("+", record)}>+</Button>
          </div>
          <Button onClick={() => handleItemAdd(record)} danger dashed="true">
            Add
          </Button>
        </Flex>
      ),
    },
    {
      title: "Special Request",
      key: "comment",
      render: (record) => (
        <Input.TextArea name={`comment-${record.id}`} rows={1} autoSize />
      ),
    },
  ];

  const handleQuantityClick = (op, item) => {
    let itemQuantity = document.querySelector(`[name="quantity-${item.id}"]`);

    if (op === "+") {
      itemQuantity.value++;
    } else if (op === "-") {
      if (itemQuantity.value > 1) itemQuantity.value--;
    }
  };
  const handleItemAdd = (item) => {
    let itemComment = document.querySelector(
      `[name="comment-${item.id}"]`
    ).value;
    let itemQuantity = parseInt(
      document.querySelector(
        `[name="quantity-${item.id}"]`
      ).value
    ); // Ensure quantity is an integer
    dispatch(
      addItem({ item, quantity: itemQuantity, comment: itemComment })
    );
  };
  

  return (
    <>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={categ?.items}
        pagination={false}
        scroll={{
          y: 275,
        }}
        style={{ width: "100%" }}
      />
    </>
  );
};
