function DashboardIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.5"
        height="21.5"
        viewBox="0 0 21.5 21.5"
      >
        <g data-name="Iconly/Light/Chart" transform="translate(.75 .75)">
          <g
            className="menu-item-color-control"
            fill="rgba(0,0,0,0)"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          >
            <path d="M5.371 8.202v6.86"></path>
            <path d="M10.038 4.919v10.143"></path>
            <path d="M14.628 11.827v3.235"></path>
            <path d="M14.686 0H5.314C2.048 0 0 2.312 0 5.585v8.83C0 17.688 2.038 20 5.314 20h9.371C17.962 20 20 17.688 20 14.415v-8.83C20 2.312 17.962 0 14.686 0z"></path>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default DashboardIcon;
