import React from "react";

function FrontOfficeIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.753"
        height="23.023"
        viewBox="0 0 17.753 23.023"
      >
        <defs>
          <clipPath id="clip-path">
            <path
              fill="rgba(0,0,0,0)"
              color="currentColor"
              stroke="currentColor"
              d="M0 0H17.753V23.023H0z"
              data-name="Rectangle 18241"
            ></path>
          </clipPath>
        </defs>
        <g data-name="Group 70297">
          <g clipPath="url(#clip-path)" data-name="Group 70296">
            <path
              d="M17.382 8.907h-1.173a1.552 1.552 0 00.89-.732 1.351 1.351 0 000-1.167 1.988 1.988 0 00-1.506-.908 8.258 8.258 0 00-1.1-.156 11.628 11.628 0 00-2.045 0 7.884 7.884 0 00-1.1.157 2.165 2.165 0 00-1.509.913 1.306 1.306 0 00-.132.582 1.351 1.351 0 00.132.584 1.562 1.562 0 00.886.732H5.567v-1.22h3a.848.848 0 00.552-.34 1.14 1.14 0 00.163-.452c0-1.112 0-2.018-.013-2.689C9.25 2.9 9.214 2.76 9.178 2.724a.942.942 0 00-.891-.632H1.466a.945.945 0 00-.944.942v3.714a.946.946 0 00.944.946h2.771v1.215H.371a.366.366 0 00-.261.108.371.371 0 00-.11.262l.007 2.246a.371.371 0 00.372.371h.753v10.663a.371.371 0 00.372.371h14.744a.372.372 0 00.372-.371V11.894h.762a.372.372 0 00.371-.372V9.279a.371.371 0 00-.371-.372M11 7.592a.508.508 0 01.051-.231 1.212 1.212 0 01.84-.447A5.831 5.831 0 0112.7 6.8a8.764 8.764 0 011.535 0 6 6 0 01.808.114c.452.093.74.245.833.441a.554.554 0 010 .48c-.093.2-.387.351-.837.44a5.581 5.581 0 01-.808.116 9.348 9.348 0 01-1.27.019 1.239 1.239 0 01-.267-.019 5.43 5.43 0 01-.806-.115c-.451-.089-.739-.241-.835-.439A.569.569 0 0111 7.594zM1.406 6.586V3.2a.361.361 0 01.36-.361H7.99a.361.361 0 01.361.361v3.385a.361.361 0 01-.361.361H1.766a.361.361 0 01-.36-.361m13.9 15.243H2.44v-9.934h12.87zm.7-11.023H1.747V10h14.26z"
              data-name="Path 33231"
              transform="translate(0 .093)"
            ></path>
            <path
              d="M4.7 4.022a.768.768 0 10.769.769.769.769 0 00-.769-.769m0 .989a.22.22 0 11.22-.22.22.22 0 01-.22.22"
              data-name="Path 33232"
              transform="translate(.174 .178)"
            ></path>
            <path
              d="M13 4.991A2.5 2.5 0 1010.5 2.5 2.5 2.5 0 0013 4.991m0-3.941a1.445 1.445 0 11-1.445 1.445A1.447 1.447 0 0113 1.05"
              data-name="Path 33233"
              transform="translate(.466)"
            ></path>
          </g>
        </g>
      </svg>
    </span>
  );
}

export default FrontOfficeIcon;
