import { Button, Descriptions } from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import NestedService from "./NestedService";
import EditOutletModal from "./EditOutletModal";
import { useState, useEffect } from "react";
import DOMPurify from 'dompurify';

// render description html
const renderHTML = (htmlString) => {
  const cleanHTML = DOMPurify.sanitize(htmlString);
  return (
    <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
  );
};
const OutletItem = ({ item, refetch }) => {
  const [editItemModal, setEditItemModal] = useState(false);
  const [extraFieldsParsed, setExtraFieldsParsed] = useState(null);

  useEffect(() => {
    if (typeof item?.extraFields === "string") {
      try {
        const parsedExtraFields = JSON.parse(item.extraFields);
        setExtraFieldsParsed(parsedExtraFields);
      } catch (e) {
        console.error("Error parsing extraFields:", e);
      }
    } else {
      setExtraFieldsParsed(item.extraFields);
    }
  }, [item]);

  if (!extraFieldsParsed) return null; // Ensure the extraFields are parsed before rendering the content

  return (
    <>
      <Descriptions
        column={1}
        bordered
        layout="horizontal"
        className="descriptionItem"
      >
        <DescriptionsItem label="Restaurant Phone">
          {extraFieldsParsed?.phone}
        </DescriptionsItem>
        <DescriptionsItem label="Description">
          {renderHTML(extraFieldsParsed?.description?.english)}
        </DescriptionsItem>
        <DescriptionsItem label="Outlet Type">
          {extraFieldsParsed?.outletType?.english}
        </DescriptionsItem>
        <DescriptionsItem label="Location">
          {extraFieldsParsed?.location?.name?.english}
        </DescriptionsItem>
        <DescriptionsItem label="Opening Hours">
          {extraFieldsParsed?.openingHours?.from} to{" "}
          {extraFieldsParsed?.openingHours?.to}
        </DescriptionsItem>
        {extraFieldsParsed?.openingHours?.description?.english && (
          <DescriptionsItem label="Opening Hours Description">
            {extraFieldsParsed?.openingHours?.description?.english}
          </DescriptionsItem>
          
        )}
      </Descriptions>
      <Button style={{ marginTop: 10 }} onClick={() => setEditItemModal(true)}>
        Edit Details
      </Button>

      <EditOutletModal
        setEditItemModal={setEditItemModal}
        editItemModal={editItemModal}
        refetch={refetch}
        item={item}
      />
      <NestedService serviceID={item?.serviceDetails?.id} />
    </>
  );
};

export default OutletItem;
