import { Table, Typography, Input, Button, DatePicker } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import { HiOutlineX, HiOutlineCheck } from "react-icons/hi";

dayjs.extend(isBetween); // Extend dayjs with the isBetween plugin

const { RangePicker } = DatePicker;

const Reservations = () => {
  const auth = JSON.parse(localStorage.getItem("user"));
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      pageSizeOptions: ["10", "20", "50", "100"],
      showSizeChanger: true,
    },
  });

  // Helper function for fetching the data
  const fetchData = (pagination, filters, sorter) => {
    setLoading(true);
    const sortOrder = sorter.order === "ascend" ? "asc" : "desc";
    const sortField = sorter.field || "bookingNumber"; // Default to sorting by bookingNumber
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL
        }/reservations/get-reservations?hotel=${selectedHotel.hotelID ? selectedHotel.hotelID : 100378095
        }&page=${pagination.current}&limit=${pagination.pageSize}&sortField=${sortField}&sortOrder=${sortOrder}`,
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.reservations);
        setTotalRecords(res.data.totalCount); // Assuming the total number of records is provided by the API
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  // Helper function for resetting filters and reloading data
  const resetFilters = (clearFilters, confirm) => {
    clearFilters(); 
    confirm(); 
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1, 
      },
    });
    fetchData({ current: 1, pageSize: tableParams.pagination.pageSize }, {}, {});
  };

  // Columns with search, filters, and sorting
  const columns = [
    {
      title: "Resv. ID",
      dataIndex: "bookingNumber",
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Resv. ID"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            size="small"
            style={{ width: "100%", marginBottom: 8 }}
          >
            Search
          </Button>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={() => resetFilters(clearFilters, confirm)}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.bookingNumber
          ? record.bookingNumber.toString().toLowerCase().includes(value.toLowerCase())
          : "",
    },
    {
      title: "Email",
      dataIndex: ["reservationBy", "email"],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Email"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            size="small"
            style={{ width: "100%", marginBottom: 8 }}
          >
            Search
          </Button>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={() => resetFilters(clearFilters, confirm)}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.reservationBy && record.reservationBy.email
          ? record.reservationBy.email.toLowerCase().includes(value.toLowerCase())
          : "",
    },
    {
      title: "Room Number",
      dataIndex: ["roomDetails", "roomNo"],
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Room Number"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={confirm}
            size="small"
            style={{ width: "100%", marginBottom: 8 }}
          >
            Search
          </Button>
          <Button
            size="small"
            style={{ width: "100%" }}
            onClick={() => resetFilters(clearFilters, confirm)}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) =>
        record.roomDetails && record.roomDetails.roomNo
          ? record.roomDetails.roomNo.toString().toLowerCase().includes(value.toLowerCase())
          : "",
    },
    {
      title: "Stay",
      children: [
        {
          title: "From",
          dataIndex: "fromDate",
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
              <RangePicker
                onChange={(dates, dateStrings) => {
                  setSelectedKeys(dates ? [dates] : []);
                }}
                style={{ marginBottom: 8, display: "block" }}
              />
              <Button
                type="primary"
                onClick={confirm}
                size="small"
                style={{ width: "100%", marginBottom: 8 }}
              >
                Search
              </Button>
              <Button
                size="small"
                style={{ width: "100%" }}
                onClick={() => resetFilters(clearFilters, confirm)}
              >
                Reset
              </Button>
            </div>
          ),
          onFilter: (value, record) => {
            const [startDate, endDate] = value || [];
            const fromDate = dayjs(record.fromDate);

            if (startDate && endDate && fromDate.isValid()) {
              return fromDate.isBetween(startDate, endDate, null, '[]');
            }

            return false; // Return false if no valid filter or fromDate
          },
          render: (date) => (date ? dayjs(date).format("YYYY-MM-DD") : "N/A"),
        },
        {
          title: "Until",
          dataIndex: "toDate",
          render: (date) => (date ? dayjs(date).format("YYYY-MM-DD") : "N/A"),
        },
      ],
    },
    {
      title: "Boarding",
      dataIndex: "roomType",
      render: (roomType) => roomType || "N/A", // Show roomType without filters
    },
    {
      title: "Reservation Details",
      children: [
        {
          title: "Checked In",
          dataIndex: "checkedin",
          render: (status) => (status ? <HiOutlineCheck /> : <HiOutlineX />),
          align: "center",
        },
        {
          title: "Checked Out",
          dataIndex: "checkedout",
          render: (status) => (status ? <HiOutlineCheck /> : <HiOutlineX />),
          align: "center",
        },
      ],
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      render: (price) => (price !== null && price !== undefined ? `$${price.toFixed(2)}` : "N/A"),
    },
  ];

  useEffect(() => {
    fetchData(tableParams.pagination, {}, {});
  }, [selectedHotel]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
    });
    fetchData(pagination, filters, sorter); // Re-fetch data when pagination or sorting changes
  };

  return (
    <div>
      <div className="table-header">
        <Typography.Title level={2}>Reservations</Typography.Title>
      </div>
      <Table
        className="resv-table"
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{
          ...tableParams.pagination,
          total: totalRecords, // Total number of records for pagination
        }}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Reservations;
