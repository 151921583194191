function MaintenanceIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.013"
        height="20.627"
        viewBox="0 0 21.013 20.627"
      >
        <g
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          data-name="Group 68884"
          transform="translate(.597 .621)"
        >
          <path
            d="M11.281 14.959l3.684 3.917a1.949 1.949 0 002.877 0 2.261 2.261 0 000-3.06l-3.684-3.917z"
            data-name="Path 17923"
          ></path>
          <path
            d="M2.201 18.792a2.55 2.55 0 010-3.449l8.866-9.426a3.951 3.951 0 01.754-4.448A4.52 4.52 0 0116.591.24l-2.832 3.012.611 2.6 2.447.651 2.833-3.012a5.235 5.235 0 01-1.156 5.071 3.4 3.4 0 01-4.182.8l-8.867 9.427a2.218 2.218 0 01-1.621.714 2.221 2.221 0 01-1.623-.711zm.858-1.724a.79.79 0 00.764.813.79.79 0 00.764-.813.789.789 0 00-.764-.813.789.789 0 00-.765.813z"
            data-name="Union 1"
          ></path>
          <path
            d="M8.027 6.908L5.351 4.064l-.764-2.439L1.529 0 0 1.625l1.529 3.251 2.293.813L6.5 8.534z"
            data-name="Path 17925"
          ></path>
        </g>
      </svg>
    </span>
  );
}

export default MaintenanceIcon;
