import { Typography } from "antd";
import Orders from "./Orders";
// import StaffOrders from "./StaffOrders";
// import SupervisorOrders from "./SupervisorOrders";

const OrdersController = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  // const adminRoles = [1, 12, 3, 4, 14];
  // const supervisorRoles = [5, 7, 9];
  // const staffRoles = [6, 8, 10];

  return user.roles.id ? (
    <Orders />
  ) : (
    <Typography.Title> Not Authorized </Typography.Title>
  );
};

export default OrdersController;
