import { Form, Input, Modal, Skeleton } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

const ViewOrderModal = ({
  orderID,
  orderDetailsModal,
  setOrderDetailsModal,
  refetch,
}) => {
  const [parseExtraFields, setParseExtraFields] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();

  const fetchOrder = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/orders?id=${orderID}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("Response:", response);
      const orderData = response?.data?.order;
      setData(orderData);

      // Parse extra fields only if available and in JSON format
      if (orderData?.extraFields) {
        setParseExtraFields(JSON.parse(orderData.extraFields));
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching order:", error);
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/orders?id=${orderID}`,
        { staffNotes: values.feedback },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      );
      form.resetFields();
      setOrderDetailsModal(false);
      refetch(); // Refetch data after update
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    fetchOrder();
  }, [orderID]);

  return (
    <div>
      <Modal
        destroyOnClose
        okText="Update"
        onOk={() => {
          form
            .validateFields()
            .then((values) => onFinish(values))
            .catch((info) => console.log("Validation Failed:", info));
        }}
        onCancel={() => setOrderDetailsModal(false)}
        open={orderDetailsModal}
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <div>
            <h2 className="grouped-info-title">Assignee Details</h2>
            <div className="grouped-info">
              <p>
                <b>Name: </b>
                {data?.assignedUserDetails?.userProfile?.firstName}{" "}
                {data?.assignedUserDetails?.userProfile?.lastName}
              </p>
              <p>
                <b>Phone Number: </b>
                {data?.assignedUserDetails?.userProfile?.mobileNumber}
              </p>
              {data.area === null ? (
                <p>
                  <b>Room No: </b>
                  {data?.roomDetails?.roomNo}
                </p>
              ) : (
                <p>
                  <b>Area: </b>
                  {data?.areaDetails?.name}
                </p>
              )}
            </div>

            <h2 className="grouped-info-title">Order Details</h2>
            <div className="grouped-info">
              <p>
                <b>Created Date: </b>
                {dayjs(data?.createdAt).format("DD/MM/YYYY - HH:mm")}
              </p>
              <p>
                <b>Status: </b>
                {data?.status}
              </p>
              <p>
                <b>Service: </b>
                {data.orderItems[0]?.itemDetails?.serviceCategoryDetails?.serviceDetails?.name}
              </p>
              {data?.orderItems?.map((item) => (
                <p key={item.id}>
                  <b>Quantity: </b>
                  {item.quantity}x {item.itemDetails?.name}
                  {item.itemDetails?.price ? ` - $${item.itemDetails.price}` : ""}
                  {item.comment ? ` | Comment: ${item.comment}` : ""}
                </p>
              ))}
              <p>
                <b>Assigned to: </b>
                {data.assignedUserDetails?.email || "N/A"}
              </p>

              {/* Conditionally render fields based on the type of extraFields */}
              {parseExtraFields.selectedDate && parseExtraFields.selectedDuration ? (
                <>
                  <p>
                    <b>Date: </b>
                    {dayjs(parseExtraFields.selectedDate).format("YYYY-MM-DD")}
                  </p>
                  <p>
                    <b>Time: </b>
                    {dayjs(parseExtraFields.selectedDate).format("h A")}
                  </p>
                  <p>
                    <b>Duration: </b>
                    {parseExtraFields.selectedDuration}
                    <b> </b>Hours
                  </p>
                </>
              ) : parseExtraFields.dateTime ? (
                <>
                  <p>
                    <b>Date: </b>
                    {dayjs(parseExtraFields.dateTime).format("YYYY-MM-DD")}
                  </p>
                  <p>
                    <b>Time: </b>
                    {dayjs(parseExtraFields.dateTime).format("HH:mm")}
                  </p>
                  <p>
                    <b>Adults: </b>
                    {parseExtraFields.adults || "N/A"}
                  </p>
                  <p>
                    <b>Children: </b>
                    {parseExtraFields.children || "N/A"}
                  </p>
                  <p>
                    <b>Special Requests: </b>
                    {parseExtraFields.specialRequests || "None"}
                  </p>
                </>
              ) : null}
            </div>

            <Form form={form} layout="vertical" name="update-staff-feedback">
              <Form.Item
                name="feedback"
                initialValue={data.staffNotes}
                label="Staff Feedback"
              >
                <Input.TextArea />
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ViewOrderModal;
