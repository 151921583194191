import { Button, Flex, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import EditHotelRoomsModal from "./EditHotelRoomsModal";
import CreateHotelRoomModal from "./CreateHotelRoomModal";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const HotelRooms = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const selectedHotel = useSelector((state) => state.hotelReducer.hotel);
  const auth = JSON.parse(localStorage.getItem("user"));

  const columns = [
    {
      title: "Room Number",
      dataIndex: "roomNo",
      key: "roomNo",
      sorter: (a, b) => a.roomNo - b.roomNo, // Ensure sorting in ascending order
    },
    {
      title: "Action",
      key: "actions",
      render: (record) => (
        <>
          <EditHotelRoomsModal refetch={fetchHotelRooms} data={record} />
          {record.active ? (
            <Button
              loading={loading}
              onClick={() => handleDeactivate(record.id)}
              style={{ marginLeft: 16 }}
              danger
              type="dashed"
            >
              Deactivate
            </Button>
          ) : (
            <Button
              onClick={() => handleActivate(record.id)}
              style={{ marginLeft: 16 }}
              type="primary"
            >
              Activate
            </Button>
          )}
          <Button
            onClick={() => handleDelete(record.id)}
            style={{ marginLeft: 16 }}
            type="primary"
            danger
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const handleDelete = (roomID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#0d375c",
      confirmButtonText: "Yes, delete it!",
      preConfirm: () => {
        return axios
          .delete(
            `${import.meta.env.VITE_APP_BASE_API_URL}/hotelRooms?id=${roomID}`,
            {
              headers: {
                Authorization: `Bearer ${auth.data.accessToken}`,
              },
            }
          )
          .then(() => {
            fetchHotelRooms();
            Swal.fire("Deleted!", "Room has been deleted.", "success");
          });
      },
    });
  };

  const handleDeactivate = (roomID) => {
    setLoading(true);
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelRooms?id=${roomID}`,
        {
          active: false,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        }
      )
      .then(() => {
        fetchHotelRooms();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleActivate = (roomID) => {
    setLoading(true);
    axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelRooms?id=${roomID}`,
        {
          active: true,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        }
      )
      .then(() => {
        fetchHotelRooms();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchHotelRooms = () => {
    setLoading(true);
    axios
      .get(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelRooms/get-rooms?hotel=${selectedHotel.hotelID ? selectedHotel.hotelID : 100378095
        }`,
        {
          headers: {
            Authorization: `Bearer ${auth.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        // Sort the rooms by roomNo in ascending order before setting the table data
        const sortedRooms = res.data.rooms.sort((a, b) => a.roomNo - b.roomNo);
        setTableData(sortedRooms);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchHotelRooms();
  }, [selectedHotel.hotelID]);

  return (
    <div>
      <Flex style={{ marginBottom: 16 }} justify="end">
        <Button onClick={() => setCreateModal(true)}>Create Hotel Room</Button>
      </Flex>
      <Table
        rowKey={(record) => record.id}
        loading={loading}
        columns={columns}
        pagination={false}
        dataSource={tableData}
      />
      <CreateHotelRoomModal
        createModal={createModal}
        setCreateModal={setCreateModal}
        refetch={fetchHotelRooms}
      />
    </div>
  );
};

export default HotelRooms;
