import { useLocation, useNavigate } from "react-router-dom";
import "../assets/scss/Login.scss";
import "../assets/scss/BgSlider.scss";
import axios from "axios";
import stellaLogo from "../assets/logo_star_10.png";
import pic1 from "../assets/StellaPhotos/01.jpg";
import pic2 from "../assets/StellaPhotos/02.jpg";
import pic3 from "../assets/StellaPhotos/03.jpg";
import pic4 from "../assets/StellaPhotos/04.jpg";
import pic5 from "../assets/StellaPhotos/05.jpg";
import { Button, Form, Input, Typography } from "antd";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";

import { register } from "swiper/element/bundle";
register();

const Login = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const onFinish = async (values) => {
    await axios
      .post(`${import.meta.env.VITE_APP_BASE_API_URL}/users/signin`, {
        email: values.email,
        password: values.password,
      })
      .then(function (response) {
        const item = {
          loginDate: dayjs(),
          data: response.data,
          roles: response.data.userProfile.userDetails.roleDetails,
        };
        localStorage.setItem("user", JSON.stringify(item));
        if (from === "/logout" || from === "/login" || from === "/") {
          navigate("/");
        } else {
          navigate(from, { replace: true });
        }
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="login-page-wrapper">
      <div className="login-page">
        <div className="login-box">
          <div className="illustration-wrapper">
            <img src={stellaLogo} alt="Login" />
          </div>

          <Form
            name="login-form"
            initialValues={{ remember: false }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Typography.Title
              level={2}
              style={{
                textAlign: "center",
                marginBottom: 30,
                textTransform: "uppercase",
                fontWeight: "bold",
                color: "#0d375c",
              }}
            >
              Dashboard Login
            </Typography.Title>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password size="small" placeholder="Password" />
            </Form.Item>

            {/* <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item> */}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                LOGIN
              </Button>
            </Form.Item>
          </Form>

          <div className="kinetics_socket">
            <p>
              Designed & Developed by{" "}
              <a href="https://www.kineticsdubai.com/">Kinetics</a> ©2023
            </p>
          </div>
        </div>
      </div>
      <div className="bgSlider">
        <swiper-container
          className="swiper-container"
          modules={[EffectFade]}
          effect="fade"
          Autoplay
        >
          <swiper-slide>
            <img src={pic1} />
          </swiper-slide>
          <swiper-slide>
            <img src={pic2} />
          </swiper-slide>
          <swiper-slide>
            <img src={pic3} />
          </swiper-slide>
          <swiper-slide>
            <img src={pic4} />
          </swiper-slide>
          <swiper-slide>
            <img src={pic5} />
          </swiper-slide>
        </swiper-container>
      </div>
    </div>
  );
};

export default Login;
//