import { Button, message } from "antd";
import dayjs from "dayjs";
import { BiSpreadsheet } from "react-icons/bi";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx"; // Import SheetJS library

const DownloadDataButton = ({ data }) => {
  const selectedHotel = useSelector(
    (state) => state.hotelReducer.hotel?.hotelName || state.hotelReducer.defaultHotel?.hotelName
  );
  
  const [messageApi, contextHolder] = message.useMessage();

  const exportToExcel = () => {
    if (!data || data.length === 0) {
      messageApi.open({
        type: "error",
        content: "There's no data to be exported",
      });
      return;
    }

    try {
      // Handle both object and string values for selectedHotel
      const hotelName = typeof selectedHotel === 'object'
        ? selectedHotel.english || selectedHotel.name || 'UnknownHotel'
        : selectedHotel || 'UnknownHotel';
      // Map the data to a simple array of objects (as needed for exporting)
      const exportData = data.map((order) => ({
        OrderID: order.id,
        Status: order.status,
        Comment: order.comment || "N/A",
        CreatedAt: dayjs(order.createdAt).format("DD/MM/YYYY - H:mm"),
        // Extract details for each order item
        ItemName_EN: order.orderItems
          .map((item) => item.itemDetails?.name?.english || "N/A")
          .join(", "),
        ItemName_AR: order.orderItems
          .map((item) => item.itemDetails?.name?.arabic || "N/A")
          .join(", "),
        ItemQuantity: order.orderItems.map((item) => item.quantity || 0).join(", "),
        Service: order.orderType?.english || "N/A",
        CreatorEmail: order.creator?.email || "N/A",
        CreatorRole: order.creator?.roleDetails?.name || "N/A",
      }));

      // Create a new workbook and add a worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(exportData);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

      // Generate Excel file and trigger download
      XLSX.writeFile(
        workbook,
        `${hotelName} - OrdersData - ${dayjs().format("YYYY-MM-DD")}.xlsx`
      );
    } catch (error) {
      console.error('Error exporting data:', error);
      messageApi.open({
        type: "error",
        content: "An error occurred while exporting data.",
      });
    }
  };

  return (
    <Button icon={<BiSpreadsheet />} type="primary" onClick={exportToExcel}>
      {contextHolder}
      Export Sheet
    </Button>
  );
};

export default DownloadDataButton;
