import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ConfigProvider, Spin } from "antd";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store.js";
import { PersistGate } from "redux-persist/integration/react";
import { LoadingOutlined } from "@ant-design/icons";

Spin.setDefaultIndicator(<LoadingOutlined />);

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#0d375c",
              colorDanger: "#FABD00",
            },
          }}
        >
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
