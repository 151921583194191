import { Button, Form, Input, Modal } from "antd";
import Swal from "sweetalert2";
import axios from "axios";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";

const EditHotelAreaModal = ({ data, refetch }) => {
  const [modal, setModal] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        areaName: data.name,
        areaDesc: data.description,
      });
    }
  }, [data, form]);

  const onFinish = async (values) => {
    await axios
      .patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/hotelAreas?id=${data.id}`,
        {
          name: values.areaName,
          description: values.areaDesc,
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((response) => {
        form.resetFields();
        refetch();
        setModal(false);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const handleModalCancel = () => {
    form.resetFields();
    setModal(false);
  };

  return (
    <>
      <Button onClick={() => setModal(true)}>Edit</Button>
      <Modal
        title="Edit Hotel Area"
        visible={modal}
        onCancel={handleModalCancel}
        destroyOnClose
        okText="Edit Hotel"
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
        centered
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            areaName: data?.name,
            areaDesc: data?.description,
          }}
          name="editHotelAreaModal"
        >
          <Form.Item label="Name" name="areaName" rules={[{ required: true, message: 'Please input the area name!' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Description" name="areaDesc" rules={[{ required: true, message: 'Please input the area description!' }]}>
            {/* <Input.TextArea /> */}
            <ReactQuill theme="snow" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditHotelAreaModal;
