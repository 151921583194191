import { Button, Form, Input, Modal } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import UploadItemIcon from "../../components/UploadIcon";

const ViewExcursionsItem = ({ item, refetch }) => {
  const [editItemModal, setEditItemModal] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));


  const [image, setimage] = useState("");

  // States for multilingual fields
  const [description, setDescription] = useState("");
  const [descriptionRU, setDescriptionRU] = useState("");
  const [descriptionAR, setDescriptionAR] = useState("");
  const [information, setInformation] = useState("");
  const [informationRU, setInformationRU] = useState("");
  const [informationAR, setInformationAR] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [termsAndConditionsRU, setTermsAndConditionsRU] = useState("");
  const [termsAndConditionsAR, setTermsAndConditionsAR] = useState("");

  // Initialize form values on modal open
  useEffect(() => {
    if (editItemModal) {
      setDescription(item?.description?.english);
      setDescriptionRU(item?.description?.russian);
      setDescriptionAR(item?.description?.arabic);
      const extraFields = item?.extraFields ? JSON.parse(item.extraFields) : {};
      setInformation(extraFields?.information?.english);
      setInformationRU(extraFields?.information?.russian);
      setInformationAR(extraFields?.information?.arabic);
      setTermsAndConditions(extraFields?.termsAndConditions?.english);
      setTermsAndConditionsRU(extraFields?.termsAndConditions?.russian);
      setTermsAndConditionsAR(extraFields?.termsAndConditions?.arabic);
    }
  }, [item, editItemModal]);
  useEffect(() => {
    if (item?.icon) {
      setimage(item.icon);
    }
  }, [item]);


  const onFinish = async (values) => {
    setLoading(true);
    const dataPayload = {
      name: {
        english: values.itemName,
        russian: values.itemNameRU,
        arabic: values.itemNameAR,
      },
      description: {
        english: description,
        arabic: descriptionAR,
        russian: descriptionRU,
      },
      // Directly assign the object to extraFields instead of stringifying it
      extraFields: {
        location: {
          lat: values.locationLat,
          lng: values.locationLng,
          name: values.locationName,
        },
        information: {
          english: information,
          arabic: informationAR,
          russian: informationRU,
        },
        termsAndConditions: {
          english: termsAndConditions,
          arabic: termsAndConditionsAR,
          russian: termsAndConditionsRU,
        },
        
      },
      icon: image,
    };
  
    // Check if an icon was uploaded and add it to the images array
    if (values.icon && typeof values.icon === "object") {
      dataPayload.extraFields.images = [values.icon]; // Update images field within extraFields
    }
  
    try {
      await axios.patch(
        `${import.meta.env.VITE_APP_BASE_API_URL}/items?id=${item.id}`,
        dataPayload,
        {
          headers: { Authorization: `Bearer ${user.data.accessToken}` },
        }
      );
      refetch();
      setEditItemModal(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    } finally {
      setLoading(false);
    }
  };
  
  const handleCancel = () => {
    form.resetFields();
    setEditItemModal(false);
  };

  return (
    <>
      <Button style={{ marginRight: 16 }} onClick={() => setEditItemModal(true)}>
        View
      </Button>
      <Modal
        confirmLoading={loading}
        okText={"Edit Item"}
        onOk={() => form.validateFields().then(onFinish)}
        onCancel={handleCancel}
        open={editItemModal}
        centered
      >
        <Form
          form={form}
          initialValues={{
            itemName: item?.name?.english,
            itemNameAR: item?.name?.arabic,
            itemNameRU: item?.name?.russian,
            icon: image,
          }}
          layout="vertical"
        >
          <Form.Item name="icon">
            <UploadItemIcon
              value={image ? image : ""} // Ensure this gets the first image link or an empty string
              onChange={(imageURL) => {
                console.log("Uploaded Image URL:", imageURL);
                setimage(imageURL); // Update image list
              }}
            />
          </Form.Item>
          <Form.Item name="itemName" label="Item Name (English)" rules={[{ required: true, message: "Please input the item name!" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="itemNameRU" label="Item Name (Russian)" rules={[{ required: true, message: "Please input the item name!" }]}>
            <Input />
          </Form.Item>
          <Form.Item name="itemNameAR" label="Item Name (Arabic)" rules={[{ required: true, message: "Please input the item name!" }]}>
            <Input dir="rtl" />
          </Form.Item>

          <Form.Item  label="Description (English)" rules={[{ required: true, message: "Please input the item Description!" }]}>
            <ReactQuill theme="snow" value={description} onChange={setDescription} />
          </Form.Item>
          <Form.Item  label="Description (Russian)" rules={[{ required: true, message: "Please input the item Description!" }]}>
            <ReactQuill theme="snow" value={descriptionRU} onChange={setDescriptionRU} />
          </Form.Item>
          <Form.Item  label="Description (Arabic)" rules={[{ required: true, message: "Please input the item Description!" }]}>
            <ReactQuill theme="snow" value={descriptionAR} onChange={setDescriptionAR} />
          </Form.Item>

          <Form.Item label="Information (English)">
            <ReactQuill theme="snow" value={information} onChange={setInformation} />
          </Form.Item>
          <Form.Item label="Information (Russian)">
            <ReactQuill theme="snow" value={informationRU} onChange={setInformationRU} />
          </Form.Item>
          <Form.Item label="Information (Arabic)">
            <ReactQuill theme="snow" value={informationAR} onChange={setInformationAR} />
          </Form.Item>

          <Form.Item label="Terms and Conditions (English)">
            <ReactQuill theme="snow" value={termsAndConditions} onChange={setTermsAndConditions} />
          </Form.Item>
          <Form.Item label="Terms and Conditions (Russian)">
            <ReactQuill theme="snow" value={termsAndConditionsRU} onChange={setTermsAndConditionsRU} />
          </Form.Item>
          <Form.Item label="Terms and Conditions (Arabic)">
            <ReactQuill theme="snow" value={termsAndConditionsAR} onChange={setTermsAndConditionsAR} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ViewExcursionsItem;
