import React from "react";

function HousekeepingIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.985"
        height="20.773"
        data-name="Group 70204"
        viewBox="0 0 21.985 20.773"
      >
        <defs>
          <clipPath id="clip-path">
            <path d="M0 0H21.985V20.773H0z" data-name="Rectangle 18182"></path>
          </clipPath>
        </defs>
        <g clipPath="url(#clip-path)" data-name="Group 70203">
          <path
            d="M8.328 11.057A5.529 5.529 0 102.8 5.528a5.535 5.535 0 005.527 5.529m0-9.557A4.029 4.029 0 114.3 5.528 4.032 4.032 0 018.328 1.5"
            data-name="Path 33177"
          ></path>
          <path
            d="M21.879 18.751a.94.94 0 00-.115-.17 6.078 6.078 0 01-1.228-2.514.747.747 0 00.308-.629 2.088 2.088 0 00-1.883-2.016V1.313A1.163 1.163 0 0017.8.152a1.163 1.163 0 00-1.161 1.161v12.11a2.074 2.074 0 00-1.559.955 6.724 6.724 0 00-2.064-.744 17.843 17.843 0 00-2.434-.344 25.911 25.911 0 00-4.5 0 17.5 17.5 0 00-2.461.343c-.62.142-2.69.708-3.326 2.024A2.956 2.956 0 000 16.951a3.036 3.036 0 00.293 1.307.673.673 0 00.067.113 4.605 4.605 0 003.325 1.913 16.286 16.286 0 002.41.345c.751.04 1.509.059 2.27.059q1.1 0 2.2-.055A16.449 16.449 0 0013 20.282a8.019 8.019 0 001.083-.3.762.762 0 00.077.048 9.236 9.236 0 00.956.346.753.753 0 00.216.032h.006a.759.759 0 00.208.087 9.3 9.3 0 002.047.274h.016a.738.738 0 00.187-.024.738.738 0 00.187.024H18a9.268 9.268 0 002.05-.273.759.759 0 00.208-.087h.006a.753.753 0 00.216-.032 9.081 9.081 0 00.948-.343.752.752 0 00.057-.026.934.934 0 00.394-1.261m-18.327-.012a3.093 3.093 0 01-1.93-1.179 1.5 1.5 0 01-.122-.6v-.008a1.449 1.449 0 01.146-.64A3.4 3.4 0 013.552 15.2zm8.191-2.1H4.916v-1.71c.424-.063.851-.113 1.282-.142a24.507 24.507 0 014.266 0c.426.03.853.082 1.279.146zM13.1 15.2a2.808 2.808 0 011.9 1.1 6.066 6.066 0 01-1.053 2.129 5.762 5.762 0 01-.839.279zm8.026 4.142a8.475 8.475 0 01-.872.315A5.264 5.264 0 0119.1 16.7a.187.187 0 00-.185-.171H18.9a.186.186 0 00-.17.2 5.675 5.675 0 001.13 3.033 8.473 8.473 0 01-1.881.252v-3.08a.187.187 0 00-.374 0v3.091a8.473 8.473 0 01-1.881-.252 5.675 5.675 0 001.13-3.033.186.186 0 00-.17-.2h-.015a.186.186 0 00-.186.171 5.264 5.264 0 01-1.155 2.955 8.717 8.717 0 01-.872-.315h-.006a.165.165 0 01-.1-.116.171.171 0 01.036-.149 6.9 6.9 0 001.463-3.141.391.391 0 00.007-.076.4.4 0 00-.361-.4 1.341 1.341 0 011.338-1.3.548.548 0 00.546-.546V1.313A.411.411 0 0117.791.9h.009a.411.411 0 01.411.411v12.3a.548.548 0 00.546.546 1.339 1.339 0 011.338 1.3.406.406 0 00-.358.45v.03a6.911 6.911 0 001.451 3.126.18.18 0 01.039.156.182.182 0 01-.1.126"
            data-name="Path 33178"
          ></path>
        </g>
      </svg>
    </span>
  );
}

export default HousekeepingIcon;
