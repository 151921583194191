function NotificationsIcon() {
  return (
    <span className="anticon ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.944"
        height="18.249"
        data-name="Group 68978"
        viewBox="0 0 16.944 18.249"
      >
        <defs>
          <clipPath id="clip-path">
            <path
              fill="currentColor"
              d="M0 0H16.944V18.249H0z"
              data-name="Rectangle 18177"
            ></path>
          </clipPath>
        </defs>
        <g clipPath="url(#clip-path)" data-name="Group 68977">
          <path
            fill="currentColor"
            d="M.24 13.054a4.2 4.2 0 01.613-.821q.373-.394.767-.774a2.048 2.048 0 00.409-.624 4.421 4.421 0 00.255-.81q.1-.445.158-.946t.118-1.026a11.54 11.54 0 01.448-2.764 5.526 5.526 0 011.083-2.04 4.173 4.173 0 011.832-1.213A2.835 2.835 0 016.837.577 2.432 2.432 0 018.468 0 2.446 2.446 0 0110.1.577a2.826 2.826 0 01.918 1.459 4.174 4.174 0 011.832 1.212 5.528 5.528 0 011.083 2.04 11.55 11.55 0 01.448 2.764q.057.523.115 1.025a9.545 9.545 0 00.154.946 4.24 4.24 0 00.258.81 2.1 2.1 0 00.412.624q.394.38.767.774a4.2 4.2 0 01.613.821 1.807 1.807 0 01.24.9 1.075 1.075 0 01-.366.846 1.442 1.442 0 01-.99.323H11.81a3.158 3.158 0 01-.455 1.542 3.281 3.281 0 01-1.169 1.147 3.319 3.319 0 01-1.717.437 3.305 3.305 0 01-1.71-.437 3.27 3.27 0 01-1.169-1.151 3.226 3.226 0 01-.463-1.538H1.355a1.439 1.439 0 01-.993-.321A1.079 1.079 0 010 13.954a1.807 1.807 0 01.24-.9m14.71.549v-.1q-.108-.143-.3-.351t-.42-.452q-.23-.244-.452-.5a2.446 2.446 0 01-.423-.685 5.337 5.337 0 01-.287-.914q-.115-.509-.186-1.093t-.115-1.23a9.452 9.452 0 00-.419-2.632 3.652 3.652 0 00-.9-1.509 3.018 3.018 0 00-1.258-.706.587.587 0 01-.276-.136.408.408 0 01-.114-.276 1.793 1.793 0 00-.392-1.079 1.172 1.172 0 00-.939-.412 1.164 1.164 0 00-.932.412 1.793 1.793 0 00-.394 1.079.437.437 0 01-.118.273.583.583 0 01-.269.136 3.045 3.045 0 00-1.262.706 3.572 3.572 0 00-.9 1.509 9.816 9.816 0 00-.42 2.632Q4.13 8.92 4.059 9.5t-.187 1.1a5.344 5.344 0 01-.287.914 2.449 2.449 0 01-.423.685q-.222.258-.455.5l-.43.452a4.2 4.2 0 00-.3.351v.1zm-7.718 2.843a1.665 1.665 0 001.237.5 1.677 1.677 0 001.24-.5 1.948 1.948 0 00.538-1.323H6.69a1.984 1.984 0 00.541 1.323"
            data-name="Path 33156"
          ></path>
        </g>
      </svg>
    </span>
  );
}

export default NotificationsIcon;
