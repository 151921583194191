import { PlusOutlined } from "@ant-design/icons";
import { Button, Collapse, Result, Spin, Typography } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import ServiceItem from "../../components/ServiceItem";
import AddServiceCategory from "../../components/AddServiceCategory";
import DeleteServiceCategory from "../../components/DeleteServiceCategory";
import AddServiceItem from "../../components/AddServiceItemModal";
import { useSelector } from "react-redux";
import AddOutletServiceItem from "../../components/AddOutletServiceItem";

const NestedService = ({ serviceID }) => {
  const hotelLoading = useSelector((state) => state.hotelReducer.loadingHotel);

  const [data, setData] = useState([]);
  const [addItemModal, setAddItemModal] = useState(false);
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [clickedCategory, setClickedCategory] = useState({
    id: null,
    name: "",
  });
  const user = JSON.parse(localStorage.getItem("user"));

  const fetchService = () => {
    setLoading(true);

    axios
      .get(
        `${
          import.meta.env.VITE_APP_BASE_API_URL
        }/serviceCategories/get-categories?service=${serviceID}`,
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchService();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const genExtra = (id, catname) => (
    <div className="actions">
      <PlusOutlined
        onClick={(event) => {
          let temp = clickedCategory;
          temp.id = id;
          temp.name = catname;
          setClickedCategory(temp);
          setAddItemModal(true);
          event.stopPropagation();
        }}
      />
    </div>
  );

  const items = data?.serviceCategories?.map((cat) => {
    return {
      key: cat.id,
      label: cat.name?.english,

      children: (
        <div className="items-wrapper">
          {cat.items.map((item) => {
            return (
              <ServiceItem refetch={fetchService} key={item.id} item={item} />
            );
          })}
        </div>
      ),
      extra: genExtra(cat.id, cat?.name?.english),
    };
  });
  return (
    <>
      <Typography.Title level={4}>Menu</Typography.Title>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
          marginTop: 16,
        }}
        className="top-controls"
      >
        <Button
          onClick={() => setAddCategoryModal(true)}
          type="primary"
          style={{ marginRight: 16, textAlign: "right" }}
        >
          Add Menu Category
        </Button>
        <Button
          type="dashed"
          danger
          onClick={() => setDeleteCategoryModal(true)}
        >
          Delete Menu Category
        </Button>
      </div>

      {loading || hotelLoading ? (
        <Spin
          style={{ position: "absolute", left: "50%", top: "50%" }}
          size="large"
        />
      ) : items?.length === 0 ? (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, there's no available categories, please contact your adminstrator"
        />
      ) : (
        <Collapse accordion items={items} />
      )}
      <AddServiceCategory
        refetch={fetchService}
        setAddCategoryModal={setAddCategoryModal}
        addCategoryModal={addCategoryModal}
        serviceID={serviceID}
      />
      <DeleteServiceCategory
        refetch={fetchService}
        setDeleteCategoryModal={setDeleteCategoryModal}
        deleteCategoryModal={deleteCategoryModal}
        categories={data?.serviceCategories}
      />
      <AddOutletServiceItem
        addItemModal={addItemModal}
        refetch={fetchService}
        setAddItemModal={setAddItemModal}
        clickedCategory={clickedCategory}
      />
    </>
  );
};

export default NestedService;
