import { Form, Input, InputNumber, Modal, Select } from "antd";
import axios from "axios";
import Swal from "sweetalert2";

const CreateLoyalityTier = ({ createModal, setCreateModal, refetch }) => {
  const [form] = Form.useForm();
  const user = JSON.parse(localStorage.getItem("user"));
  const onFinish = async (values) => {
    console.log("Success:", values);
    await axios
      .post(
        `${import.meta.env.VITE_APP_BASE_API_URL}/loyaltytiers`,
        {
          name: values.tierName,
          minPoints: values.minPoints,
          maxPoints: values.maxPoints,
          tierDiscount: values.tierDiscount,
          vip: values.vip,
          termsAndConditions: values.terms,
        },
        {
          headers: {
            Authorization: `Bearer ${user.data.accessToken}`,
          },
        }
      )
      .then(function (response) {
        form.resetFields();
        console.log(response);
        refetch();
        setCreateModal(false);
      })
      .catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setCreateModal(false);
  };
  return (
    <Modal
      okText={"Add Tier"}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      onCancel={handleCancel}
      open={createModal}
      centered
    >
      <Form form={form} layout="vertical" name="add-loyality-tier-form">
        <Form.Item
          name="tierName"
          label="Tier Name"
          rules={[
            {
              required: true,
              message: "Please input the tier name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="minPoints"
          label="Minimum Points"
          rules={[
            {
              required: true,
              message: "Please input the Minimum Points!",
            },
          ]}
        >
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item
          name="maxPoints"
          label="Maximum Points"
          rules={[
            {
              required: true,
              message: "Please input the Maximum Points!",
            },
          ]}
        >
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item
          name="vip"
          label="VIP"
          rules={[
            {
              required: true,
              message: "Please select VIP State!",
            },
          ]}
        >
          <Select>
            <Select.Option value={true}>True</Select.Option>
            <Select.Option value={false}>False</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="tierDiscount"
          label="Tier Discount"
          rules={[
            {
              required: true,
              message: "Please input the Maximum Points!",
            },
          ]}
        >
          <InputNumber controls={false} />
        </Form.Item>

        <Form.Item
          name="terms"
          label="Terms and Conditions"
          rules={[
            {
              required: true,
              message: "Please input the Terms and Conditions!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateLoyalityTier;
