import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "antd";
import axios from "axios";
import "../../../assets/scss/General.scss";

const ViewLoyaltyUser = () => {
  const { id } = useParams(); // Get user ID from the route
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [transLoading, setTransLoading] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    // Fetch user information
    const fetchUserInfo = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${import.meta.env.VITE_APP_BASE_API_URL}/loyaltyPoints/find-by-id/${id}`,
          {
            headers: {
              Authorization: `Bearer ${user.data.accessToken}`,
            },
          }
        );
        console.log("User Info Response:", response.data); // Log the response to check structure
        setUserInfo(response.data); // Correct mapping to userLoyaltyPoints
        setTransLoading(false); // Stop the loading spinner once data is fetched
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user information:", error);
        setLoading(false);
      }
    };
    fetchUserInfo();
  }, [id, user.data.accessToken]);

  // Columns for the transaction history table
  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Amount",
      dataIndex: "transactionAmount", // Adjusted to correct key name in the response
      key: "amount",
    },
    {
      title: "Date",
      dataIndex: ["reservationDetails", "fromDate"], // Adjusted to point to 'fromDate' in the response
      key: "date",
    },
    // Add more columns based on the structure of transaction data
  ];

  return (
    <div>
      {loading ? (
        <p>Loading user info...</p>
      ) : (
        <div className="user-info">
          <h2>User Information</h2>
          <p>
            <b>ID: </b>
            {userInfo?.userLoyaltyPoints?.id || "N/A"}
          </p>
          <p>
            <b>Email: </b>
            {userInfo?.userLoyaltyPoints?.userDetails?.email || "N/A"}
          </p>
          <p>
            <b>Total Points: </b>
            {userInfo?.userLoyaltyPoints?.totalPoints || "N/A"}
          </p>
          <p>
            <b>Expiry Date: </b>
            {userInfo?.userLoyaltyPoints?.expiryDate || "N/A"}
          </p>
        </div>
      )}

      <h2>Transaction History</h2>
      <Table
        dataSource={userInfo?.userTransaction ? [userInfo.userTransaction] : []} // Ensure dataSource is an array
        columns={columns}
        rowKey="id"
        loading={transLoading}
      />
    </div>
  );
};

export default ViewLoyaltyUser;
